import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, ChangeDetectorRef, HostListener } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { forkJoin, Subject, Observable, Subscription } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { HomeComponent } from '../home/home.component';
import { NavigationStart, Router } from '@angular/router';
import { PrescriptionComponent } from '../modals/prescription/prescription.component';
import { CovidComponent } from '../configuration/covid/covid.component';
import { ViewFileComponent } from '../modals/view-file/view-file.component';
import { VitalsComponent } from '../modals/vitals/vitals.component';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from '../modals/image-preview/image-preview.component';
import { FileService } from 'src/app/shared/services/file.service';
import { Common, Avail } from 'src/app/common';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ChatMessageDto } from 'src/app/shared/models/user';
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { DatePipe, JsonPipe, LocationStrategy } from '@angular/common';
import { LabService } from 'src/app/shared/axaservices/lab.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MenuService } from 'src/app/shared/axaservices/menu.service';
import { error } from 'protractor';

declare let $: any;
declare let stopVideo: any;
declare let stopVideoPocGiven: any;
declare let isPocGivenstopVideo: any;
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { ChatService } from 'src/app/shared/services/chat.service';
// import { ThrowStmt } from '@angular/compiler';
import { BsModalRef } from 'ngx-bootstrap';
import { ActiveDoctorsListComponent } from '../pro/consulatation/consulatation/active-doctors-list/active-doctors-list.component';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientEmrComponent } from '../masters/laboratory-master/patient-emr/patient-emr.component';
import { EyemdlComponent } from '../modals/eyemdl/eyemdl.component';

@Component({
  selector: 'app-new-poc',
  templateUrl: './new-poc.component.html',
  styleUrls: ['./new-poc.component.css'],
  providers: [DatePipe]
})
export class NewPocComponent implements OnInit {

  @ViewChild('scrollBottom') private scrollBottom: ElementRef;
  @ViewChild('search') searchElement: ElementRef;
  readonly imageTrigger: Subject<void> = new Subject<void>();
  bmi:any;
  isukhub: boolean = false;
  pack_quantity: any;
  searchCheckedLst: Array<any> = [];
  searchDrugs: boolean = true;
  tenantId: any;
  error?: string;
  searchLst: Array<any> = [];
  searchdrugdropdownSettings = {};
  drugs_id: any;
  drug_list = []; hidehmrbs = true;
  featureName: any;
  newpoc: any;
  digitalPrescription_checking: any;
  isFeature: boolean = false;
  pocForm: FormGroup;
  submitted = false;
  userId: any;
  showTestField = 0;
  subtypeList: Array<any> = [];
  investigationMaster: Array<any> = [];
  dropdownSettings = {};
  ccdropdownSettings = {};
  diagnosysdropdownSettings = {};
  drugSettings = {};
  drugNameSettings = {};
  featureDetails: any
  searchDrug: any
  dropdownSettings_referral = {}
  searchDrugLst: Array<any> = [];
  srhLst: Array<any> = [];
  shwABHDetails: boolean;
  drug_name: any;
  drug_id: any;
  selectedsearchItems: any;
  minDate = new Date;
  curDate = new Date();
  isEnableCovid19: boolean;
  selectedItems = []; benHistory = []; lstbenHstry = []; prevItem = {}; userData: any; docData: any; benId = 0;
  benData: any;
  // benData = { first_name: '', middle_name: '', last_name: '', mobile: null, age: null, address: null, gender_id: null, member_mobile: null };
  doc = { first_name: '', last_name: '', qualification: '', superspecialiy: '', mci: null, address: null };
  chiefComplaintList: Array<any> = [];
  diagnosislList: Array<any> = [];
  drugNameLst: Array<any> = [];
  directionLst: Array<any> = [];
  dosageLst: Array<any> = [];
  specializationList: Array<any> = [];
  super_SpecialisationList: Array<any> = [];
  doctorList: Array<any> = [];
  drugtypesList: any;
  diagnosysgroupList: any;
  drugnamesList: any;
  completeDrugList: any = [];
  investigationsList: any;
  directionsList: any;
  instructionList: any;
  hospitalId: any;
  symptoms: string = '';
  member_mobile2: number;
  videocall_startdatatime = new Date();
  maxChars = 500;
  findingsChar = '';
  chars = 0;
  selectedRefHp: any = [];
  typeOfdiagnosis: any; opnd: any; opndn: any;
  searchDrugNameList: any = [];
  orgn = window.location.href.includes('localhost') ? environment.applicationURL : window.origin;
  selectedList = [];
  pdSlctd = []; qualificationsList = []; superSpecialityDegreeList = []; systolic = ''; diastolic = ''; emergencyComment = '';
  yesContinue: boolean = false;
  pocCc = ''; pocVitals = ''; pocHistory = ''; pocPd = ''; pocIn = ''; pocAdvice = ''; medName: any; medType: '';
  pocDrug = { drug_type: '', drug_name: '', dsg: '', dir: '', ins: '', noofdays: '' }; provDgns = ''; isEdit = false; pocItem: any;
  is_self: boolean = true; depDtls: any; ptntInfo: any; ptntDtls: any; depId = 0;
  family_member = { first_name: '', last_name: '', age: null, gender_id: null, mobile: null, address: null, member_mobile: null, id: null };
  // family_member:any
  registered_name: string = ''; gendersList: Array<any> = []; tempErrMsg: boolean;
  dT: any; dN = []; isDgtl = 'Upload'; dgtlFile: any; lstLabs = []; lstPhmcy = []; labSettings = {}; phrmcySettings = {}; lab = ''; phrmcy = '';
  //#region Video Uplaod
  isCamAcsd: boolean; httpOptions: any; imgPath = ''; upldFile = ''; isPrvwHdn = true; isLcl = location.href.includes('localhost');
  public showWebcam = false; public allowCameraSwitch = true; public multipleWebcamsAvailable = false; public deviceId: string;
  public videoOptions: MediaTrackConstraints = {}; public errors: WebcamInitError[] = []; public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>(); private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  //#endregion

  lstStates: any[] = []; lstRegns: any[] = []; lstDist: any[] = []; lstTelgLab: any[] = []; lstTelgPhrm: any[] = [];
  dipl = 'dhanush'; labAddrs = ''; phrmcyAddrs = '';
  appSrce = ''; bg = ''; presLogo = ''; isShowPDOthr = false; isShowDP = !location.href.includes('vidmedanh');
  isQA = location.href.includes('qa') || location.href.includes('vidmed2') || location.href.includes('localhost');
  ccCgh = false; ccPrd = false; ccRef = false; ccSwl = false; ccOther = false; pdOther = false; moreCC = false; morePD = false; moreIN = false; moreDN = false;
  lstAllCC: any; lstAllPD: any; lstAllIN: any; lstAllDN: any; txtMoreCC = 'Show More'; txtMorePD = 'Show More'; txtMoreIN = 'Show More'; txtMoreDN = 'Show More';
  isOthrDrg = false;
  moreStatus = true;
  textMore = " MoreOptions...";
  limitNumber = 5;
  totalData: any[];
  vitalsList: Array<any> = []; ConsultationList: Array<any> = [];
  vtlDt = ''; vtlTm = ''; benficiarydatafromstorage: any; doctordetailsfromstorage: any;
  discoonecttype = 'Doctor Disconnected';
  private Subscription: Subscription; selectedItemscc: Array<any> = []; ccdata = []; selectedItemstd: Array<any> = [];
  selectedItemsinvestgations: Array<any> = []; typeofDiagnosisLst: any = [];
  checkedLst: any = [];
  isPcChecked = false;
  pcrequired: any;
  consType: any = 'New';
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  orgId = JSON.parse(sessionStorage.getItem('currentTenant'));
  orgname = sessionStorage.getItem('orgName');
  isukt = false;
  id_proof_path: any;
  imagePath: any; Flagslist: any; pocpatientHistory = false;
  id_proof_path2: any;
  id_proof_path3: any;
  chatArray: any;
  chatUser = '';
  messagechat = '';
  style1 = false;
  style2 = false;
  count: boolean;
  memberType: any;
  familyMem_id: any;
  memAge: any; hubId: any; center_id: any; Toh = ''; Rh = ''
  ben_guard_id: any; statusget; statusdata: any; drugsData = []; benHistoryRef = [];
  guardian_id: any; hospTypeId: any; refhospId: any; refdoctorName: any; benAgentID: any
  HospTypeList = []; refHospList = []; procedurevalue; refdivshow = false; openvideotag = false; beforepocid; hospitalName = null; formStockData: any; pocmember = false; memberData: any; hospitalType = null;
  confirmtitle = 'Patient/Health Care Worker'; nooftitle = ''; placeHolders = [];
  healthidaddress: any; healthidnumber: any;
  refHospListNew: any[];
  selectedHosp: any = '';
  filterdOptions = [];
  refStatus: any;
  refhospitalName: any = null;
  hideOtherDrugLabel: boolean;
  public getScreenWidth: any;
  public getScreenHeight: any;
  audio_file_path: any;
  audioPath: any = '';
  audio_file_path3: string;
  audio_file_path2: string;
  playlist = []
  isSosChecked: boolean;
  isEnableAdviceAllField: boolean;
  enableConsultationTypeInPOC: boolean;
  currLat: number;
  currLng: number;
  consultation_no: any;
  tenantChecking: any;
  packageservices: any;
  previewSelectedInvestigation: any = [];
  medid: any;
  enableInsuranceDetailsInPoc: any;
  enableProvisionalDiagnosis: any;
  showCreatedOn: any;
  disableSearchByDrugName: any;
  searchByDrugName: any;
  searchByDrugNameList: any = [];
  drugId: any;
  tokenData: any[];
  patient_interaction_id: any;
  referralHospTypeList: any = [];
  HimsReferral: boolean=false;
  isHimsreferral: boolean=false;
  bmiValue: string;
  EnableHIMSFileinPOC: boolean=false
  EnableOptimetryDetailsInPOC: boolean=false

  // human MMU
  enableHumanMMU: boolean=false
  drugNameId: any;
  drugTypeId: any;
  adminName: any;
  drugSettingsDrug: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; enableCheckAll: boolean; allowSearchFilter: boolean; };
  administrationList: any[]=[];
  dTMMU:any;
  dNMMU:any
  drugtypesListMMU: any[]=[];
  drug_listMMU: any[]=[];
  constructor(private el: ElementRef, private toastr: ToastrService,
    private locationData: LocationStrategy,
    private labService: LabService
    , public homeComponent: HomeComponent
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService,
    private menuservice: MenuService
    , private masterService: MasterService
    , private ntfcnSvc: NotificationService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , public activeModal: NgbActiveModal
    , private authServ: AuthenticationService
    , private fileSvc: FileService
    , private datePipe: DatePipe
    , private router: Router
    , private userServ: UserService,
    public changeDetection: ChangeDetectorRef
    , private chatService: ChatService) {
    this.getFeatersdetails();
    this.Subscription = this.router.events
      .pipe(
        // The "events" stream contains all the navigation events. For this demo,
        // though, we only care about the NavigationStart event as it contains
        // information about what initiated the navigation sequence.
        filter(
          (event: any) => {
            return (event instanceof NavigationStart);
          }
        )
      )
      .subscribe(
        (event: NavigationStart) => {

          //  console.group("NavigationStart Event");
          // Every navigation sequence is given a unique ID. Even "popstate"
          // navigations are really just "roll forward" navigations that get
          // a new, unique ID.
          // console.log("navigation id:", event.id);
          // console.log("route:", event.url);
          // The "navigationTrigger" will be one of:
          // --
          // - imperative (ie, user clicked a link).
          // - popstate (ie, browser controlled change such as Back button).
          // - hashchange
          // --
          // NOTE: I am not sure what triggers the "hashchange" type.
          // console.log("trigger:", event.navigationTrigger);
          if ('popstate' == event.navigationTrigger) this.gotoConsultation();

          // This "restoredState" property is defined when the navigation
          // event is triggered by a "popstate" event (ex, back / forward
          // buttons). It will contain the ID of the earlier navigation event
          // to which the browser is returning.
          // --
          // CAUTION: This ID may not be part of the current page rendering.
          // This value is pulled out of the browser; and, may exist across
          // page refreshes.
          if (event.restoredState) {

            console.warn(
              "restoring navigation id:",
              event.restoredState.navigationId
            );

          }

          console.groupEnd();

        }
      )
      ;
    config.backdrop = 'static';
    config.keyboard = false;
    this.minDate = new Date();
    // if (!customElements.get('openvidu')) {
    //   customElements.define('openvidu', HTMLElement);
    // }
    this.homeComponent.isDoctor = false;
    (<any>window).isPrescriptionSumitted = false;
    //   this.chatService.count.subscribe( value => {
    //     this.count = value;
    //     if(this.count == true){
    //    this.style1 = true;
    //    this.style2 = false;
    //     } else{
    //       this.style2 = true;
    //       this.style1 = false;
    //     }
    // });
    // if (this.count = true){
    //   this.toastr.info('New Message Arrived')
    // }
    const content = document.body;
    content.classList.remove("sidenav-toggled");
    document.body.style.backgroundColor = "#fff";
    // document.getElementById('footerHome').style.display='none';

    const footer = document.getElementById('footerHome');
    footer.setAttribute("style", "display:none !important");


  }


  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {

    var value: number = e.target['scrollingElement'].scrollTop
    // if (value>100) {

    //   const style = document.getElementById('style-1');
    //   const style1 = document.getElementById('style-2');
    //   const style2 = document.getElementById('style-3');
    //   style.setAttribute("style", "height:330px;overflow:auto");
    //   style1.setAttribute("style", "height:330px;overflow:auto");
    //   style2.setAttribute("style", "height:330px;overflow:auto");

    // }
    // else {
    //   const style = document.getElementById('style-1');
    //   const style1 = document.getElementById('style-2');
    //   const style2 = document.getElementById('style-3');
    //   style.setAttribute("style", "height:auto;overflow:none");
    //   style1.setAttribute("style", "height:auto;overflow:none");
    //   style2.setAttribute("style", "height:auto;overflow:none");

    // }
  }


  ngOnDestroy(): void {
    sessionStorage.isPatient = false;
    this.changeMenu();
    this.Subscription.unsubscribe();
    this.imageTrigger.complete();
  }
  //Get current locations Lat Long 25-11-2022	
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.currLat = position.coords.latitude;
        this.currLng = position.coords.longitude;
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  onFile(evnt: any) {
    if (evnt.target.files.length > 0) {
      var fileExtsn: string = evnt.target.files[0].type;
      if (fileExtsn.includes('jpg') || fileExtsn.includes('jpeg') || fileExtsn.includes('png'))
        this.dgtlFile = evnt.target.files[0], this.upldFile = evnt.target.files[0].name;
      else
        this.toastr.error('Please upload image files like jpg/jpeg/png');
    }
  }

  viewFile() {
    if (this.dgtlFile) {
      let view_File = this.dgtlFile;
      const fileExtension: string = view_File.name.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
        const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
        if (view_File) {
          const reader = new FileReader();
          reader.readAsDataURL(view_File); // read file as data url
          reader.onload = (event: any) => { modalRef.componentInstance.image = event.target.result; };
        }
      }
    }
    else
      this.toastr.info('No file found');
  }


  chkTstrMsg() {
    this.toastr.show('Message from Jquery');
  }

  OthrDrg() {
    this.isOthrDrg = !this.isOthrDrg;
    return;
  }

  ngOnInit(): void {
    // this.chatService.openWebSocket();
    // this.chatUser=sessionStorage.getItem('userchatname')

    this.tenantChecking = JSON.parse(sessionStorage.getItem('currentUser'))?.tenantData?.tenantModuleList;
    this.tenantChecking.forEach(f => {
      if (f.componentName == environment.tenantConfig.isUKT) {
        this.isukhub = true;
      } else {
        this.isukhub = false;

      }
    })
    this.getUserPermission();
    this.initFormControls();
    this.leftMenu(), this.LoadDefaultData();
    this.getCurrentLocation();
    
    history.pushState(null, null, window.location.href);
    this.locationData.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    sessionStorage.setItem('isPocGiven', 'false');
    const fromPatient = JSON.parse(sessionStorage.isPatient || 'false');
    if (fromPatient) {
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
      dragElement.style.display = 'none';
    }
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    }), this.authServ.appSrceSubject.subscribe(s => {
      this.appSrce = s,
        this.bg = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.background}`,
        this.presLogo = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.logo_prescrption}`;
    });
    //#region Voice Recognition
    $(document).ready(function () {
      var speechRecognition = window['webkitSpeechRecognition'], recognition = new speechRecognition(), isOn = false, ta = $('#taST'),
        actvty = $('#activity'), eBtn = $('#btnSpch'), eRing = $('#ringDiv'), txtBg = 'background', mT = '', recStp = 'stop recording',
        txtClr = 'clear text', startBg = '#69d425', stopBg = '#dc2727', speechCntnt = '', lstTbIds = ['#tbCC', '#otherComplaints',
          '#presentIllness', '#familyhistory', '#temperature', '#pulse', '#systolic', '#diastolic', '#hemoglobin', '#oxigen_count', '#diabetic_value', '#rbsk', '#history',
          '#allergies', '#otherInvestigation', '#restExercise', '#diet', '#followAdvice', '#doctorComment', '#referral', '#instruction'],
        lstTbHdrs = ['chief complaints', 'other complaints', 'history of present illness', 'family history', 'temperature', 'pulse', 'systolic',
          'diastolic', 'hemoglobin', 'oxigen_count', 'diabetic_value', 'rbs', 'past history', 'allergies', 'other investigations', 'rest or exercise', 'diet', 'follow advice',
          'doctor comments', 'referral', 'instructions']; // $('#eTest').click();
      recognition.continuous = true, actvty.text('Voice Recognition ready'), $('#ringDiv').hide(), eBtn.css(txtBg, stopBg);
      recognition.onstart = function () { actvty.text('Voice Recognition is ON'); }
      recognition.onspeechend = function () { actvty.text('Voice Recognition is OFF'); }
      recognition.onerror = function () { actvty.text('Error Occurred, Try again.!'); }
      recognition.onresult = function (event) {
        var trnScrpt = event.results[event.resultIndex][0].transcript;
        speechCntnt += ' ' + trnScrpt;
        if (speechCntnt.length > 0) {
          if (speechCntnt.includes(recStp))
            recognition.stop(), isOn = !isOn, chkTBVals(), eBtn.css(txtBg, stopBg), eRing.hide();
          if (speechCntnt.includes(txtClr))
            ta.val(mT), speechCntnt = mT, chkTBVals(true);
        }
        ta.val(speechCntnt.replace(recStp, mT));
      }
      eBtn.click(function (event) {
        isOn = !isOn;
        if (speechCntnt.length)
          speechCntnt += mT;
        if (isOn)
          recognition.start(), eBtn.css(txtBg, startBg), eRing.show(), speechCntnt = speechCntnt.replace(recStp, mT);
        else
          recognition.stop(), chkTBVals(), eBtn.css(txtBg, stopBg), eRing.hide();
      });
      ta.on('input', function () { speechCntnt += $(this).val(); });
      function chkTBVals(isClr: boolean = false) {
        if (speechCntnt.length > 0) {
          let arrTVs = [], ttlCntnt = speechCntnt.toLowerCase().replace(recStp, mT).trim();
          lstTbHdrs.forEach((e, i) => {
            if (isClr)
              $(lstTbIds[i]).val(mT);
            else {
              let hdrTxt = lstTbHdrs[lstTbHdrs.length - i - 1], lstHdrs = [], lstTxts = [];
              if ((hdrTxt[hdrTxt.length - 1] == 's' && !ttlCntnt.includes(hdrTxt)))
                hdrTxt = hdrTxt.substring(0, hdrTxt.length - 1);
              if (!ttlCntnt.includes(hdrTxt))
                arrTVs.push(mT);
              else {
                let tbVal = ttlCntnt.split(hdrTxt.toLowerCase())[1] != undefined ? ttlCntnt.split(hdrTxt)[1].trim() : mT;
                arrTVs.push(tbVal.toUpperCase()), ttlCntnt = ttlCntnt.replace(tbVal, mT).replace(hdrTxt, mT).trim(); // arrTVs.push(ttlCntnt.split(hdrTxt.toLowerCase())[1] != undefined ? ttlCntnt.split(hdrTxt)[1] : mT);
              }
            }
          });
          arrTVs = arrTVs.reverse();
          lstTbIds.forEach((e, i) => { $(lstTbIds[i]).val(arrTVs[i]); });
        }
        else
          lstTbHdrs.forEach((e, i) => { $(lstTbIds[i]).val(mT); });
      }
    });
    //#endregion
    this.userId = sessionStorage.getItem('session_id');
    this.searchdrugdropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'investigation',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      enableCheckAll: false,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.ccdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'chief_complaint',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.diagnosysdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'diagnosis_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.drugSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.labSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'lab_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.phrmcySettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'pharmacy_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.drugNameSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      closeDropDownOnSelection: true,
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    // Referal Drop Down..

    this.dropdownSettings_referral = {
      singleSelection: true,
      idField: 'id',
      textField: 'hospital_name',
      selectAllText: 'Select All',
      closeDropDownOnSelection: true,
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
    this.isEdit = sessionStorage.getItem('pocEdit') != null,
      this.pocItem = this.isEdit ? JSON.parse(sessionStorage.getItem('pocEdit')) : null;
    this.is_self = this.pocItem ? this.pocItem?.advice?.is_self : ((<HTMLInputElement>document.getElementById('is_self')).value == "true");

    //console.clear();
    if (!this.is_self) { // == false
      let result = this.pocItem ? this.pocItem?.advice.family_member : (<HTMLInputElement>document.getElementById('family_member')).value || null;
      this.ptntInfo = (<HTMLInputElement>document.getElementById('patient_info')).value || null;

      if (result)
        setTimeout(() => {
          this.family_member = JSON.parse(result),
            this.depDtls = JSON.parse(result);
          this.member_mobile2 = this.family_member.member_mobile;
        }, 1000);

      if (this.ptntInfo)
        this.depId = +JSON.parse(this.ptntInfo).familyMemberId;
      // POC Submit issue here
      if (Object.keys(sessionStorage).includes('pocEditFmember')) {
        if (sessionStorage.getItem('pocEditFmember'))
          this.pocmember = true;
        this.memberData = JSON.parse(sessionStorage.getItem('pocEditFmember'));

      }
      //  console.log('member Data and family member', this.memberData, this.family_member);
      if (this.isOffLinePOC() == true) {

        this.depDtls = { first_name: '', last_name: '', gender_id: '', age: '', member_mobile: '', mobile: '', address: '' };
        this.depDtls.first_name = this.memberData?.member_name;
        this.depDtls.last_name = '';
        this.depDtls.gender_id = this.memberData?.gender_id;
        this.depDtls.age = this.memberData?.age;
        this.depDtls.member_mobile = this.memberData?.mobile;
        this.depDtls.address = '';
      }
      // this.family_data = this.family_membe
      // console.clear()
      // console.log(ptntInfo, this.depId)
    }
    // else {
    //   let result = (<HTMLInputElement>document.getElementById('family_member')).value || null;
    //   if (result)
    //     this.benData = JSON.parse(result)
    // }
    // console.log(this.benData);

    this.benficiarydatafromstorage = sessionStorage.getItem('dataBenf');
    this.doctordetailsfromstorage = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    this.refdoctorName = this.doctordetailsfromstorage.first_name + ' ' + this.doctordetailsfromstorage.last_name;

    //, this.openModal();
    this.pocForm.get('lab_id').setValue(''), this.pocForm.get('pharmacy_id').setValue('');
    this.hospitalId = this.pocItem ? this.pocItem?.pharmacy_id?.hospital_id : (<HTMLInputElement>document.getElementById('hospital_id'))?.value;
    this.symptoms = this.pocItem ? '' : (<HTMLInputElement>document.getElementById('symptoms'))?.value ?? '';
    let no = this.pocItem ? this.pocItem?.beneficiary_id?.id : (<HTMLInputElement>document.getElementById('toId'))?.value;
    let id = this.pocItem ? no : no.replace('pat_', '');
    // let id = this.pocItem ? no : no.split('_')[1];
    this.memberType = sessionStorage.getItem('mem_type');
    // need to check POC of Self or member based on that will show the data 13-05-2022
    this.benId = +id;
    // console.log('########## ben Id #########', this.benId);
    if (!this.is_self) {
      this.getBenDetails(this.benId);
      this.getMemHistoryDetails(sessionStorage.getItem('callingpatient_id_fm'));
    } else {
      this.getBenDetails(this.benId), this.getBenHistoryDetails(this.benId);

    }


    setTimeout(() => {
      this.healthidaddress = sessionStorage.getItem('health_ida')
      this.healthidnumber = sessionStorage.getItem('health_idn')
    }, 3000);
    // else {
    //   if (!window.location.href.includes('localhost')) {
    //     alert('something went wrong while doing in plan of care');
    //     this.router.navigate(['']);
    //   }
    // }
    this.userData = this.authServ.currentUserValue, this.getAllMaster(), this.getAllVitals();
    this.getAllMaster();
    this.LoadLabPharmabyDist();
    this.getallConsultations();
    this.ReferralHospital();
    // this.getDoctorDetails(this.userData.id);

    //  console.log('this.orgname',this.orgname);
    // if (this.orgname == 'Uttarakhand Telemedicine') {
    if (this.isukhub) {
      this.isukt = true;

      this.getAllHospTypes();
      // this.pocForm.controls.diagnosis.value.provisionalDiagnosis.value.setValidators(Validators.required);

      // this.statusreferdata();

    } else {
      this.isukt = false;
      this.getAllHospTypes();
    }
    // this.getUserPermission();
    setTimeout(() => {
      if (sessionStorage.getItem('fromrefpat') == 'referalpatientList') {
        this.refdivshow = true;
        this.pocForm.patchValue({ refstatus: 1 })
        this.followupclickref(this.benHistoryRef)
      } else {
        this.refdivshow = false;
      }
    }, 3000);
    this.registrationService.statusrefer().subscribe(res => {
      this.statusget = res.data;
    })
    if (this.memberType == 'false') {
      this.getcenterByMem();
      setTimeout(() => {
        this.getHubID();
      }, 2000);

    }
    if (Object.keys(sessionStorage).includes('doctortodoctor')) {
      if (sessionStorage.getItem('doctortodoctor') == 'doctortodoctorcall') {
        this.confirmtitle = 'Doctor';
      } else {
        this.confirmtitle = 'Patient/Health Care Worker';
      }
    }
    this.pocForm.get('allergy.allergies').setValue('None')
    //     setInterval(() => {
    //       this.isAcceptCall(localStorage.getItem('answer')) ;
    //  }, 30000);
    setTimeout(() => {
      if(this.enableHumanMMU){
        this.drugtypes();
        this.dropdownMMU();
        this.getAdministrations();
      }
    }, 1500);

  }
  isAcceptCall(isAccept: any) {
    if (isAccept === 'answer') {
      stopVideo();
    }
  }
  LoadDefaultData() {
    this.drugNameLst = [{ id: 1, name: 'Drug 1' }, { id: 2, name: 'Drug 2' }, { id: 3, name: 'Drug 3' }, { id: 4, name: 'Drug 4' }]
    this.directionLst = [{ id: 1, name: 'Direction 1' }, { id: 2, name: 'Direction 2' }, { id: 3, name: 'Direction 3' }, { id: 4, name: 'Direction 4' }]
    this.dosageLst = [{ id: 1, name: 'X-0-0' }, { id: 2, name: '0-X-0' }, { id: 3, name: '0-0-X' }, { id: 4, name: 'X-X-0' }, { id: 5, name: '0-X-X' }, { id: 6, name: ' X-X-X' }, { id: 7, name: 'X-0-X' }]
  }

  onEpaperChng(isChkd: boolean) {
    this.pocForm.get('is_epaper').setValue(isChkd);
  }

  initFormControls() {
    this.pocForm = this.formBuilder.group({
      is_epaper: [false],
      beneficiary_id: [''],
      doctor_id: [''],
      visit_date: [this.datePipe.transform(this.minDate, 'yyyy-MM-dd HH:mm:ss')],
      visit_start_time: [this.datePipe.transform(this.minDate, 'HH:mm:ss')],
      visit_end_time: [''],
      lab_id: [''],
      pharmacy_id: [''],
      cc_brief_history: this.formBuilder.group({
        chiefComplaints: [[], Validators.required],
        otherComplaints: [''],
        othersifany: [''],
        presentIllness: [''],
        familyhistory: ['']
      }),
      vital_history: this.formBuilder.group({
        temperature: ['', [Validators.min(85), Validators.max(110)]],
        pulse: ['', [Validators.min(40), Validators.max(200)]],
        systolic: ['', [Validators.min(50), Validators.max(250)]],
        diastolic: ['', [Validators.min(40), Validators.max(160)]],
        hemoglobin: [''],
        rbsk: [''],
        // oxigen_count: ['', [Validators.min(40), Validators.max(205), Validators.maxLength(3)]],
        oxigen_count: ['', [Validators.min(0), Validators.max(100), Validators.maxLength(3)]],
        diabetic_value: ['', [Validators.min(50), Validators.max(800), Validators.maxLength(3)]],
        height: ['', [Validators.min(30), Validators.max(240), Validators.maxLength(6)]],
        weight: ['', [Validators.min(1), Validators.max(250), Validators.maxLength(6)]],
        bmi:['']
      }),
      other_history: this.formBuilder.group({
        history: ['']
      }),
      allergy: this.formBuilder.group({
        allergies: ['None', Validators.required],
      }),
      diagnosis: this.formBuilder.group({
        provisionalDiagnosis: [[], this.enableProvisionalDiagnosis ? Validators.required : ''],
        othersifany: ['']
      }),
      investigation: this.formBuilder.group({
        master: [[]],
        otherInvestigation: ['']
      }),
      prescribed_medications: this.formBuilder.array([]),
      advice: this.formBuilder.group({
        restExercise: [''],
        diet: [''],
        followAdvice: [''],
        referral: [''],
        doctorComment: [''],
        followupDate: [null],
        qualification_id: [null],
        specialization_id: [null],
        super_qualification_id: [null],
        super_specialization_id: [null],
        referral_id: [null],
        is_self: [this.is_self]
      }),
      medical_history: this.formBuilder.group({}),
      drugs: this.formBuilder.group({
        drugType: [''],
        drugName: [''], // , Validators.required
        otherDrug: [''],
        instruction: [''],
        dT: [''],
        dN: [''],
      }),
      // require_physical_consultation : [this.isPcChecked],
      type_of_consultation: [''],
      is_referral: [],
      referral_center_id: [''],
      findings: [null],
      instructions: [null],
      referred_to: [null],
      referral_type: [null],
      reason_for_refer: [''],
      hospital_referral_type: [''],
      referral_hospital: [''],
      refstatus: [''],
      dependent_id: [null],
      remarks: this.formBuilder.group({}), //Not there in UI
      covid19: {}, //Not there in UI
      visit_no: [''],
      call_type_id: this.isOffLinePOC() ? 9 : 1,
      // "teleconference_file_path": "/home",
      referred_visit_id: "2",
      created_on: [new Date()],
      beneficiary_visit_logs: [''],
      beneficiary_visit_files: [''],
      patient_interaction_id: [this.pocItem ? +this.pocItem?.patient_interaction_id : +(<HTMLInputElement>document.getElementById('patient_interaction_id')).value],
      order_no: [this.pocItem ? +this.pocItem?.order_no : (<HTMLInputElement>document.getElementById('order_no')).value],
    });
    // this.pocForm.get('cc_brief_history.othersifany').valueChanges.subscribe(res => {
    //   if()
    // })
  }

  get f() { return this.pocForm.controls; }

  getAllVitals() {
    if (this.is_self == false && this.memberData != undefined || this.memberData != null) {
      this.depId = this.memberData.id;
    }
    this.vtlDt = '', this.vtlTm = '', this.registrationService.getByAllVitals(this.is_self ? this.benId : this.depId, this.is_self).subscribe(res => {
      if (res.status === 'OK') {
        let result = res.data;
        if (this.is_self)
          this.vitalsList = result.filter(f => f.beneficiary_member_id == null);
        else
          this.vitalsList = result.filter(f => f.beneficiary_member_id == this.depId);
        if (this.vitalsList.length > 0) {
          let vtlCapDt = '';
          if (this.vitalsList[0].capture_date) {
            vtlCapDt = this.vitalsList[0].capture_date.split(' ');
            if (vtlCapDt) {
              if (vtlCapDt[0] == this.datePipe.transform((new Date()), "yyyy-MM-dd")) {
                this.vtlDt = this.datePipe.transform(this.vitalsList[0].capture_date, "dd-MM-yyyy"),
                  this.vtlTm = this.datePipe.transform(this.vitalsList[0].capture_date, "hh:mm:ss aa")
                this.pocForm.get('vital_history.temperature').setValue(this.vitalsList[0]?.temperature),
                  this.pocForm.get('vital_history.pulse').setValue(this.vitalsList[0]?.pulse_rate),
                  this.pocForm.get('vital_history.diabetic_value').setValue(this.vitalsList[0]?.diabetic_value),
                  this.pocForm.get('vital_history.oxigen_count').setValue(this.vitalsList[0]?.oxigen_count),
                  this.pocForm.get('vital_history.height').setValue(this.vitalsList[0]?.height),
                  this.pocForm.get('vital_history.weight').setValue(this.vitalsList[0]?.weight),
                  this.pocForm.get('vital_history.bmi').setValue(this.vitalsList[0]?.bmi),
                  this.pocForm.get('vital_history.systolic').setValue(this.vitalsList[0]?.bp_value.toString().split('/')[0]),
                  this.pocForm.get('vital_history.diastolic').setValue(this.vitalsList[0]?.bp_value.toString().split('/')[1]);

              }
            }
          }
        }
      }
    });
  }
  // filterUsers() {
  //   this.filterdOptions = this.refHospListNew.filter(
  //     item => item.value.toLowerCase().includes(this.selectedHosp.toLowerCase())
  //   );
  //   console.log(this.filterdOptions);
  // }


  onSysDia() {
    this.systolic = this.pocForm.value.vital_history.systolic;
    this.diastolic = this.pocForm.value.vital_history.diastolic;

    if (this.systolic != '' && this.diastolic == '') {
      this.pocForm.get('vital_history.diastolic').setValidators([Validators.required, Validators.min(30), Validators.max(200)]);
      this.pocForm.get('vital_history.diastolic').updateValueAndValidity();
    }
    else if (this.systolic == '' && this.diastolic != '') {
      this.pocForm.get('vital_history.systolic').setValidators([Validators.required, Validators.min(50), Validators.max(400)]);
      this.pocForm.get('vital_history.systolic').updateValueAndValidity();
    }
    else {
      if (+this.diastolic > 0 && +this.systolic > 0) {
        if (+this.diastolic >= +this.systolic) {
          this.pocForm.get('vital_history.diastolic').setErrors({ 'dia': true });
          this.toastr.error('Systolic should be greater than Diastolic');
        }
      }
      else if ((this.diastolic == '' && this.systolic == '') || (+this.systolic >= +this.diastolic)) {
        this.pocForm.get('vital_history.diastolic').setErrors(null);
        // this.pocForm.get('vital_history.systolic').clearValidators();
        // this.pocForm.get('vital_history.diastolic').clearValidators();
        this.pocForm.get('vital_history.systolic').setValidators([Validators.min(50), Validators.max(400)]);
        this.pocForm.get('vital_history.diastolic').setValidators([Validators.min(30), Validators.max(200)]);
        this.pocForm.get('vital_history.systolic').updateValueAndValidity();
        this.pocForm.get('vital_history.diastolic').updateValueAndValidity();
      }
    }
  }

  superSecialityDegree(_id: number) {
    this.super_SpecialisationList = (+_id == 0) ? [] : this.superSpecialityDegreeList.find(({ id }) => +id == _id).specializations;
    this.getDoctors();
  }

  QualificationChanged(id: string) {
    this.specializationList = (+id > 0) ? this.qualificationsList.find(item => item.id == id).specializations : [];
    this.pocForm.patchValue({ advice: { specialization_id: null } });

    this.getDoctors();
  }

  getDoctors() {
    this.doctorList = [];
    let value = this.pocForm.value.advice;

    if (+value.qualification_id || +value.specialization_id || +value.super_qualification_id || +value.super_specialization_id) {
      let payload = {
        qualification_id: value.qualification_id,
        specialization_id: value.specialization_id,
        super_qualification_id: value.super_qualification_id,
        super_specialization_id: value.super_specialization_id
      };

      this.registrationService.save('doctor/getbyqualification', payload).subscribe(res => {
        if (res.data != null)
          this.doctorList = res.data;
      },
        err => { this.toastr.error(err.message) });
    }
  }

  getDrugsByType(obj) {
    this.drugnamesList = [];
    if (obj.id != '') {
      // below line commented for new API change for drug add 14-04-2023
      // this.masterService.searchbyIdMasters(`drugtypemapping/getdrugbydrugtypeid?drugTypeId=${+obj.id}`).subscribe(res => {

      this.registrationService.getDrugsListNew(+obj.id).subscribe(res => {
        if (res.data != null)
          this.drugnamesList = res.data
        if (this.drugNameLst.length > 0) {
          for (let i = 1; i <= this.drugNameLst.length; i++) {
            this.completeDrugList.push(this.drugNameLst[i]);
          }
        }
        var drug_list = []
        this.drugnamesList.forEach(function (each) {
          drug_list.push(each.drug_id)
        })
        this.drug_list = drug_list;
        console.log(this.drug_list)
        // for(let i=0;i<=this.drugNameLst.length;i++){
        //   let b = {}
        //   b['drug_name'] = 
        // }
      },
        err => { this.toastr.error(err.message) });
    }
    else
      this.drugnamesList = [];
  }

  keyUp(elementId: string) {

    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    // console.log('el',el)
    el.value = el.value.replace(/^(0*)/, "");
    // console.log('  el.value',  el.value);

  }
  onPhysicalConsultation(event) {
    this.isPcChecked = event.target.checked;
  }
  //   triggerFalseClick() {
  //     let el: HTMLElement = this.myDiv.nativeElement;
  //     el.click();
  // }
  onItemSelectInvestgations(item: any) {
    setTimeout(() => {
      const filterItem: any = this.investigationsList.filter(f => f.id == +item.id);
      this.previewSelectedInvestigation.push(filterItem[0].id);
      this.previewSelectedInvestigation = [...this.previewSelectedInvestigation];
    }, 500);

    console.log('previewSelectedInvestigation', this.previewSelectedInvestigation);
  }
  onItemDeSelectInvestgations(item: any) {
    // console.log();
    let data = this.previewSelectedInvestigation;
    data.forEach((x, i) => { if (x == item.id) data.splice(i, 1); });
    this.previewSelectedInvestigation = [...data];

    console.log('previewSelectedInvestigation', this.previewSelectedInvestigation);
  }

  onSubmit(event) {
    if (this.tempErrMsg)
      this.toastr.error('Please enter Valid Decimal format. Eg.: 98.7 or 100.2');
    else {
      this.pocForm.patchValue({
        beneficiary_id: this.benId.toString(), doctor_id: this.docData?.id.toString(),
        visit_no: this.benHistory.length + 1, visit_date: this.datePipe.transform(this.minDate, 'yyyy-MM-dd HH:mm:ss'),
        visit_start_time: this.datePipe.transform(this.minDate, 'HH:mm:ss'), visit_end_time: this.datePipe.transform(new Date(), 'HH:mm:ss'),
        advice: { followupDate: this.datePipe.transform(this.pocForm.value.advice.followupDate, 'yyyy-MM-dd HH:mm:ss') },
        is_epaper: this.pocForm.get('is_epaper').value, // false,
      }); // 33 //this.docData.id.toString()
      if (this.pocmember == false) {
        this.pocForm.patchValue({ beneficiary_id: this.benId.toString() })
      };
      const sendData = JSON.parse(JSON.stringify(this.pocForm.getRawValue()));
      sendData.is_referral=this.isHimsreferral
      if (sessionStorage.getItem('call_type') == 'Audio') {
        sendData.call_type_id = 9;
      }
      else if (this.isOffLinePOC() == true) {
        sendData.call_type_id = 13;

      } else {
        sendData.call_type_id = 1;

      }
      sendData.referred_to = sessionStorage.getItem('fromdocid');
      sendData.reason_for_refer = 'For Better Consultation';
      if (Object.keys(sessionStorage).includes('fromdocid') || sessionStorage.getItem('myId')) {
        if (this.docData?.id != sessionStorage.getItem('fromdocid')) {
          sendData.referred_to = sessionStorage.getItem('fromdocid');
          sendData.reason_for_refer = 'For Better Consultation'
        } else if (this.docData?.id == sessionStorage.getItem('fromdocid') && this.docData?.id != sessionStorage.getItem('myId')) {
          sendData.referred_to = sessionStorage.getItem('myId');
          sendData.reason_for_refer = 'For Better Consultation'
        }
        // else {
        //   console.log('else ');
        //  // sendData.referred_to = this.docData?.id;
        //   sendData.reason_for_refer = 'For Better Consultation'
        // }
      }
      let stockdata = JSON.parse(sessionStorage.getItem('agentstock'));
      if (stockdata) {
        // if(sendData.prescribed_medications.length==0){
        //  alert();
        // }
        // new quantity issue fixed 12-05-2023 #chandu
        for (let i = 0; i < stockdata.length; i++) {
          for (let j = 0; j < sendData.prescribed_medications.length; j++) {
            let qty: any = 0;

            //  setTimeout(() => {
            let drugInfo: any = this.completeDrugList.find(item => item.drug_name === sendData.prescribed_medications[j].name);
            if (drugInfo != undefined) {
              sendData.prescribed_medications[j].id = drugInfo?.id;
            }
            //  }, 1000);
            if (sendData.prescribed_medications[j].type === 'Tablets') {

              if (sendData.prescribed_medications[j].mrng) {
                qty = qty + 1;
              }
              if (sendData.prescribed_medications[j].aftr) {
                qty = qty + 1;
              }
              if (sendData.prescribed_medications[j].evng) {
                qty = qty + 1;
              }
              if (sendData.prescribed_medications[j].night) {
                qty = qty + 1;
              }
              if (sendData.prescribed_medications[j].sos) {
                qty = qty + 1;
              }
              if (sendData.prescribed_medications[j].period === 'Days') {
                qty = qty * (+sendData.prescribed_medications[j].count);
              }
              if (sendData.prescribed_medications[j].period === 'Weeks') {
                qty = qty * (+sendData.prescribed_medications[j].count * 7);
              }
              if (sendData.prescribed_medications[j].period === 'Months') {
                qty = qty * (+sendData.prescribed_medications[j].count * 30);
              }
              if (sendData.prescribed_medications[j].period === 'Years') {
                qty = qty * (+sendData.prescribed_medications[j].count * 365);
              }
              sendData.prescribed_medications[j].quantity = qty;
            } else {
              sendData.prescribed_medications[j].quantity = 1;
            }


            // sendData.prescribed_medications[j].name = sendData.prescribed_medications[j].name.toUpperCase();
            sendData.prescribed_medications[j].name = sendData.prescribed_medications[j].name.toUpperCase();
            stockdata[i].name = stockdata[i].name.toUpperCase();
            if (stockdata[i].name == sendData.prescribed_medications[j].name) {
              sendData.prescribed_medications[j].agentStock = stockdata[i].agentStock;
              sendData.prescribed_medications[j].instruction = this.pocForm.value.drugs.instruction;

            }
          }
        }
      }
      let ar = [{
        "visit_status_id": 1,
        // "visit_end_time": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        // for created on flag below line 
        "visit_end_time": this.datePipe.transform(new Date(sendData.created_on), 'yyyy-MM-dd HH:mm:ss'),
      }]
      let ar2 = [{
        "file_type_id": 10,
        "document_path": sessionStorage.getItem('sid')
      }]
      delete sendData.referral_type;
      sendData.hospital_referral_type = this.hospitalType;
      sendData.referral_hospital = this.refhospitalName;
      //  if(sendData.hospital_referral_type == ''){
      //   sendData.hospital_referral_type = null
      //  }
      //  if(sendData.referral_hospital == ''){
      //   sendData.referral_hospital = null
      //  }
      delete sendData.refstatus;
      sendData.beneficiary_visit_logs = ar;
      sendData.beneficiary_visit_files = ar2
      //sendData.advice.family_member = this.is_self ? null : this.family_member;
      // sendData.referred_to = 
      if (this.is_self) {
        sendData.advice.family_member = this.is_self ? null : this.family_member;
        // sendData['dependent_id'] = null;
      }
      else {
        sendData.advice.family_member = this.family_member;
        let memData = this.memberData !== null ? this.memberData : null
        if (memData !== null && memData !== undefined) {
          sendData.advice.family_member.first_name = this.memberData.member_name;
          sendData.advice.family_member.age = this.memberData.age;
          sendData.advice.family_member.gender_id = this.memberData.gender_id;
          sendData.advice.family_member.member_mobile = this.memberData.mobile;
          sendData.advice.family_member.id = this.memberData.id;
          sendData['dependent_id'] = this.memberData.id;
        } else {
          sendData.advice.family_member.first_name = this.family_member.first_name;
          sendData.advice.family_member.age = this.family_member.age;
          sendData.advice.family_member.gender_id = this.family_member.gender_id;
          sendData.advice.family_member.member_mobile = this.family_member.mobile;
          sendData.advice.family_member.id = this.family_member.id;
          sendData['dependent_id'] = this.family_member.id;
        }


      }
      sendData['require_physical_consultation'] = this.isPcChecked;
      if (this.consType == "New") {
        sendData['consultation_follow_up'] = false
      } else {
        sendData['consultation_follow_up'] = true
      }
      sendData.type_of_consultation = this.pocForm.value.type_of_consultation;
      sendData.findings = this.pocForm.value.findings;
      sendData.instructions = this.pocForm.value.instructions

      // try {
      //   sendData.advice.family_member.member_mobile = this.member_mobile2
      // }
      // catch (ex) {
      //   console.log(ex)
      // }
      if (this.isOffLinePOC() == false) {
        try {
          sendData.advice.family_member.member_mobile = this.member_mobile2
        }
        catch (ex) {
          console.log(ex)
        }
      }
      if (this.pocForm.get('lab_id').value != '')
        sendData.lab_id = +this.pocForm.get('lab_id').value;
      else
        sendData.lab_id = null;
      if (this.pocForm.get('pharmacy_id').value != '')
        sendData.pharmacy_id = +this.pocForm.get('pharmacy_id').value;
      else
        sendData.pharmacy_id = null;
      delete sendData.drugs;
      if (this.orgname != 'Uttarakhand Telemedicine') {
        // delete sendData.type_of_consultation;
        delete sendData.consultation_follow_up;
        delete sendData.require_physical_consultation;
      }
      if (!this.is_self)
        sendData.advice.family_member.id = this.depId > 0 ? this.depId : null;
      sendData.cc_brief_history.chiefComplaints = sendData.cc_brief_history.chiefComplaints.filter(e => e.chief_complaint !== 'Other');
      sendData.diagnosis.provisionalDiagnosis = sendData.diagnosis.provisionalDiagnosis.filter(e => e.diagnosis_name !== 'Other');
      //chandu poc check for family member cal 01-06-2022
      if (this.pocmember == true && this.memberData && this.memberData !== null) {
        sendData.beneficiary_id = this.memberData.beneficiary_id;
        // sendData.member_id= this.depId
      } else {
        sendData.beneficiary_id = this.benId;
        // sendData.member_id= this.depId
      }
      if (this.is_self == false) {
        sendData.dependent_id = sendData.advice.family_member.id;
        sendData.advice.is_self = false;
        if (typeof (sendData.advice.family_member.age) == "string") {
          sendData.advice.family_member.age = parseInt(sendData.advice.family_member.age);
        }
        if (typeof (sendData.advice.family_member.gender_id) == "string") {
          sendData.advice.family_member.gender_id = parseInt(sendData.advice.family_member.gender_id);
        }
      }
      if (this.refdivshow) {
        sendData.procedure = this.procedurevalue;
      } else {
        sendData.procedure = null;
      }
      //below Line commented for createdon flag 30-06-2023

      sendData.created_on = this.datePipe.transform(new Date(sendData.created_on), 'yyyy-MM-dd HH:mm:ss');
      sendData.visit_date = this.datePipe.transform(new Date(sendData.created_on), 'yyyy-MM-dd HH:mm:ss');
      sendData.nodeduction = true

      if (sessionStorage.pId != undefined) {
        sendData.patient_interaction_id = sessionStorage.pId,
          sendData.order_no = sessionStorage.orderNo

      }
      this.registrationService.saveData('savedoctorpoc', sendData).subscribe(res => {
        let apiResponse = res;
        this.beforepocid = res.data.id;
        if (apiResponse.status == "OK") {
          this.registrationService.deleteSessionbydocId(this.docData?.id).subscribe(res => {

          });
          sessionStorage.setItem('fromdocid', null);
          sessionStorage.setItem('myId', null);
          sessionStorage.removeItem('call_type');


          (<any>window).isPrescriptionSumitted = true;

          document.getElementById('prescriptionModalClose').click();
          // this.token();
          this.onRefHospSubmit();
          // if(this.pocForm.value.referral_type && this.hospitalName){s
          //   console.log('referral hosp check');
          //   this.onRefHospSubmit(); 
          // }
          //new lines added for the update p[atient interaction ID 18-07-2022 #chandu new
          // let formValueCheck = Object.values(this.pocForm.value.vital_history.value).find(value => value !== '')
          // console.log('formValueCheck', formValueCheck);
          // alert('Form value check');
          let PayLoad;
          if (this.pocForm.get('vital_history.temperature').value != null || this.pocForm.get('vital_history.temperature').value !== '' || this.pocForm.get('vital_history.pulse').value != null || this.pocForm.get('vital_history.pulse').value !== ''
            || this.pocForm.get('vital_history.systolic').value != null || this.pocForm.get('vital_history.systolic').value !== ''
            || this.pocForm.get('vital_history.diastolic').value != null || this.pocForm.get('vital_history.diastolic').value !== ''
            || this.pocForm.get('vital_history.diabetic_value').value != null || this.pocForm.get('vital_history.diabetic_value').value !== ''
            || this.pocForm.get('vital_history.oxigen_count').value != null || this.pocForm.get('vital_history.oxigen_count').value !== ''
            || this.pocForm.get('vital_history.height').value != null || this.pocForm.get('vital_history.height').value !== ''
            || this.pocForm.get('vital_history.weight').value != null || this.pocForm.get('vital_history.weight').value !== ''
            || this.pocForm.get('vital_history.bmi').value != null || this.pocForm.get('vital_history.bmi').value !== '') {
            let myID = sessionStorage.getItem('myId');
            if (sessionStorage.pId != undefined) {
              this.patient_interaction_id = sessionStorage.pId
            } else {
              this.patient_interaction_id = this.pocForm.get('patient_interaction_id').value
            }


            if (this.pocForm.value.vital_history) {
              //referred_to
              if (this.isukt) {
                PayLoad = {
                  "id": this.patient_interaction_id,
                  "is_vital_captured": true,
                  "is_poc_completed": true,
                  "hub_latitude": this.currLat,
                  "hub_longitude": this.currLng,
                  "beneficiary_id": res.data.beneficiary_id.id,
                  "ben_member_id": res.data.advice.is_self ? null : res.data.advice.family_member.id
                }
              } else {
                if (myID) {
                  PayLoad = {
                    "id": this.patient_interaction_id,
                    "is_vital_captured": true,
                    "is_poc_completed": true,
                    "beneficiary_id": this.benId,
                    "doctor_id": this.docData.id,
                    "referred_to": myID,
                    "is_active": true
                  }
                } else {
                  PayLoad = {
                    "id": this.patient_interaction_id,
                    "is_vital_captured": true,
                    "is_poc_completed": true,
                    "beneficiary_id": this.benId,
                    "doctor_id": this.docData.id,
                    "is_active": true
                  }
                }
              }


            }
          }

          else {
            let myID = sessionStorage.getItem('myId');
            if (this.isukt) {
              PayLoad = {
                "id": this.patient_interaction_id,
                "is_vital_captured": false,
                "is_poc_completed": true,
                "hub_latitude": this.currLat,
                "hub_longitude": this.currLng,
                "beneficiary_id": res.data.beneficiary_id.id,
                "ben_member_id": res.data.advice.is_self ? null : res.data.advice.family_member.id
              }
            } else {
              if (myID) {
                PayLoad = {
                  "id": this.patient_interaction_id,
                  "is_vital_captured": false,
                  "is_poc_completed": true,
                  "beneficiary_id": this.benId,
                  "doctor_id": this.docData.id,
                  "referred_to": myID,
                  "is_active": true
                }
              } else {
                PayLoad = {
                  "id": this.patient_interaction_id,
                  "is_vital_captured": false,
                  "is_poc_completed": true,
                  "beneficiary_id": this.benId,
                  "doctor_id": this.docData.id,
                  "is_active": true

                }
              }
            }


          }

          this.registrationService.updatePatientInteraction(PayLoad).subscribe(res => {
            //console.log('updatePatientInteraction', res);
            if (res.status === 'OK') {
              if (res.data == null || res.data.length == 0) {
                this.toastr.info(res.message);
              }
              else {
                this.toastr.info(res.message);
                sessionStorage.removeItem('pId');
                sessionStorage.removeItem('orderNo');
              }
            }
          })
          this.changeMenu(), this.submitted = false, stopVideoPocGiven(),
            this.initFormControls(),
            this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Plan of Care'),
            sessionStorage.setItem('pocEditFmember', null);
          this.clearData(),
            this.sendMailNotification(sendData);
          this.allowCameraSwitch = false;

        }
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
      //error => this.toastr.error(error));
      sessionStorage.setItem('fromdocid', null);
      sessionStorage.setItem('myId', null);
    }
  }

  onPOCEnd(sendData: any) {
    (<any>window).isPrescriptionSumitted = true;
    document.getElementById('prescriptionModalClose').click(), this.changeMenu(), this.submitted = false, stopVideoPocGiven(), this.initFormControls(),
      this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Plan of Care'), this.clearData();
  }

  sendMailNotification(sendData: any) {
    // this.ptntDtls = (<HTMLInputElement>document.getElementById('patientDetails'))?.value || null;
    // if (this.benData) {
    //   let dataBenf = JSON.parse(sessionStorage.getItem('dataBenf')), payLoad = '';
    //   if (this.is_self)
    //     payLoad = `{"email":"${dataBenf?.email}","name":"${dataBenf?.first_name + ' ' + dataBenf?.last_name}","mobile_no":"${dataBenf?.mobile}",
    //       "user_id":"${dataBenf?.user_id}","user_type":"consultation","login_name": "${dataBenf?.email}","password": "${dataBenf?.user_registration?.password}",
    //       "doctor_name":"${this.doc?.first_name + ' ' + this.doc?.last_name}","beneficiary_name":"${dataBenf?.first_name + ' ' + dataBenf?.last_name}"}`;
    //   else
    //     payLoad = `{"email":"${dataBenf?.email}","name":"${this.family_member?.first_name + ' ' + this.family_member?.last_name}",
    //       "mobile_no":"${dataBenf?.member_mobile ?? dataBenf?.mobile}","user_id":"${dataBenf?.user_id}","login_name": "${dataBenf?.email}",
    //       "password": "${dataBenf?.user_registration?.password}","doctor_name":"${this.doc?.first_name + ' ' + this.doc?.last_name}",
    //       "user_type":"consultation","beneficiary_name":"${dataBenf?.first_name + ' ' + dataBenf?.last_name}"}`;
    //   this.ntfcnSvc.sendNotification(payLoad).subscribe(s => {
    //     this.onPOCEnd(sendData);
    //   })
    // }
    // else
    //   this.onPOCEnd(sendData);
    let payLoad = `{"email":"akhilesh.o@dhanushinfotech.net","name":"Venu",
           "mobile_no":"9866255543","user_id":"1111","login_name": "9866255543@gmail.com",
           "password": "123456","doctor_name":"Nancy",
          "user_type":"consultation","beneficiary_name":"Venu"}`;
    this.ntfcnSvc.sendNotification(payLoad).subscribe(s => {
      this.onPOCEnd(sendData);
    })
  }

  uploadImg() {
    let eFile = null;
    if (this.isDgtl == 'Upload') {
      if (this.dgtlFile)
        eFile = this.dgtlFile;
    }
    else {
      if (this.webcamImage)
        eFile = this.dataURItoBlob(this.webcamImage.imageAsDataUrl);
    }
    if (eFile != null) {
      if (this.isOffLinePOC()) {
        if (this.is_self) {
          this.fileSvc.uploadFile(Common.form_Data('0', eFile, this.benId.toString(), Avail.BnfcryTxt.replace('/', ''),
            Avail.MnlPresFTId.toString(), this.pocForm.get('patient_interaction_id').value, true)).subscribe(s => {
              if (s.status == "success")
                this.toastr.success('File Uploaded successfully'), setTimeout(() => this.onSubmit(event), 5);
              else
                this.toastr.error(s.status);
            });
        } else {
          //Chandu 24-06-2022
          this.fileSvc.uploadFile(Common.form_Data('0', eFile, sessionStorage.getItem('ben_id'), Avail.BnfcryTxt.replace('/', ''),
            Avail.MnlPresFTId.toString(), this.pocForm.get('patient_interaction_id').value, true)).subscribe(s => {
              if (s.status == "success")
                this.toastr.success('File Uploaded successfully'), setTimeout(() => this.onSubmit(event), 5);
              else
                this.toastr.error(s.status);
            });

        }
      } else {
        this.fileSvc.uploadFile(Common.form_Data('0', eFile, sessionStorage.getItem('ben_id'), Avail.BnfcryTxt.replace('/', ''),
          Avail.MnlPresFTId.toString(), this.pocForm.get('patient_interaction_id').value, true)).subscribe(s => {
            if (s.status == "success")
              this.toastr.success('File Uploaded successfully'), setTimeout(() => this.onSubmit(event), 5);
            else
              this.toastr.error(s.status);
          });

      }



      // new lines added for digital prescription 
      //  if(this.is_self){
      //   this.fileSvc.uploadFile(Common.form_Data('0', eFile, this.benId.toString(), Avail.BnfcryTxt.replace('/', ''),
      //   Avail.MnlPresFTId.toString(), this.pocForm.get('patient_interaction_id').value, true)).subscribe(s => {
      //     if (s.status == "success")
      //       this.toastr.success('File Uploaded successfully'), setTimeout(() => this.onSubmit(event), 5);
      //     else
      //       this.toastr.error(s.status);
      //   });
      //  } else {
      //   this.fileSvc.uploadFile(Common.form_Data('0', eFile, this.benId.toString(), Avail.BnfcryTxt.replace('/', ''),
      //   Avail.MnlPresFTId.toString(), this.pocForm.get('patient_interaction_id').value, true)).subscribe(s => {
      //     if (s.status == "success")
      //       this.toastr.success('File Uploaded successfully'), setTimeout(() => this.onSubmit(event), 5);
      //     else
      //       this.toastr.error(s.status);
      //  });
    }
    //}
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]),
      mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length), ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ab], { type: mimeString });
  }

  emergencyExit() {
    this.pocForm.patchValue({ advice: { doctorComment: this.emergencyComment } });
    this.onSubmit(event);
  }

  getBenHistoryDetails(id) {
    this.lstbenHstry = [];
    //this.registrationService.getDataById('findbybeneficiaryid?beneficiaryId=', id).subscribe(res => {
    //findbypocbeneficiaryid?beneficiaryId
    this.registrationService.getDataById('findbypocbeneficiaryid?beneficiaryId=', id).subscribe(res => {

      this.benHistory = res.data;
      this.benHistoryRef = res.data[0];
      res.data.forEach(e => {
        let temp = e.beneficiary_visit_files;
        let resDgns = e.diagnosis.provisionalDiagnosis;
        var doc_id = e.doctor_id.id;
        //Object.keys(e)
        if (Object.keys(e).includes('prescribed_medications')) {
          e.prescribed_medications['doc_id'] = doc_id;
        }
        // let isStr = (typeof e.diagnosis?.provisionalDiagnois == 'string');
        if (resDgns.length > 0) {
          resDgns.forEach(rd => {
            this.lstbenHstry.push({ visit_date: e.visit_date, docCmnt: e.advice.doctorComment, dgns: rd?.diagnosis_name, document_path: temp });
          });
        }
      });
    }, err => { this.toastr.error(err) });
  }
  getMemHistoryDetails(id) {
    this.lstbenHstry = [];
    //this.registrationService.getDataById('findbybeneficiaryid?beneficiaryId=', id).subscribe(res => {
    //findbypocbeneficiaryid?beneficiaryId
    this.registrationService.getDataById('findbypocbeneficiaryid?dependentId=', id).subscribe(res => {
      this.benHistory = res.data;
      this.benHistoryRef = res.data[0];
      res.data.forEach(e => {
        let temp = e.beneficiary_visit_files;
        let resDgns = e.diagnosis.provisionalDiagnosis;
        var doc_id = e.doctor_id.id;
        //Object.keys(e)
        if (Object.keys(e).includes('prescribed_medications')) {
          e.prescribed_medications['doc_id'] = doc_id;
        }
        // let isStr = (typeof e.diagnosis?.provisionalDiagnois == 'string');
        if (resDgns.length > 0) {
          resDgns.forEach(rd => {
            this.lstbenHstry.push({ visit_date: e.visit_date, docCmnt: e.advice.doctorComment, dgns: rd?.diagnosis_name, document_path: temp });
          });
        }
      });
    }, err => { this.toastr.error(err) });
  }
  // , document_path:e.document_path
  onViewPresOld(item: any) {
    if (item.document_path.length > 0) {
      let temp = item.document_path.filter(f => f.file_type_id == Avail.VstFleFTId), res = temp.length > 0 ? temp[temp.length - 1] : null, link = document.createElement("a");
      if (res != null)
        this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${res.document_path.split('_')[0]}/${environment.prescription}/${res.document_path}`, link.setAttribute('href', this.imgPath), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
      else
        this.toastr.info('No files found');
    }
    else
      this.toastr.info('No files found');
  }


  getBenDetails(id) {
    let path: any;
    let patId: any;
    if (this.is_self) {
      patId = this.benId
      path = 'beneficiary/getbeneficiaryrecordbyid?beneficiaryId=';
    } else {
      patId = sessionStorage.getItem('callingpatient_id_fm');
      path = 'beneficiary/getbeneficiaryrecordbyid?memberId=';
    }
    this.registrationService.getByID(path, patId).subscribe(res => {
      /* if (this.is_self)
        this.benData = res.data;
      else
        this.registered_name = `${res.data.first_name} ${res.data.last_name}`.toUpperCase(); */
      // console.log('resp of benficiary', JSON.stringify(res));
      //  console.log('@@@@@@@@@@@@@getBenDetails@@@@@@@@@@@@@@@@@@',JSON.stringify(res));
      //sconsole.log(' res.data BEN', res.data);
      if (this.is_self) {
        sessionStorage.setItem('health_ida', res.data.health_id)
        sessionStorage.setItem('health_idn', res.data.health_id_number)
      } else {
        this.getmemDetails(sessionStorage.getItem('callingpatient_id_fm'));
      }
      if (res.data.beneficiaryMemberGuardian != null) {
        this.ben_guard_id = res.data.beneficiaryMemberGuardian.id
      }
      // this.registrationService.centersID = res.data.center_id
      this.benData = res.data, this.center_id = res.data.center_id, this.benAgentID = res.data.agent_id, this.registered_name = `${res.data.first_name} ${res.data.middle_name} ${res.data.last_name}`.toUpperCase();
      // console.log('bendata.',this.benData);
      if (this.center_id > 0) {
        //this.getReferralHospitals();
        this.getHubID();
      }
      if (this.benAgentID > 0) {
        this.getAgentdrugs();
      }
      // console.log('res.data.beneficiaryMemberGuardian',res.data.beneficiaryMemberGuardian);
      this.pocForm.patchValue({ beneficiary_id: res.data }), sessionStorage.setItem('dataBenf', JSON.stringify(this.benData));
      if (res.data.beneficiary_documents != null && res.data.beneficiary_documents != '' && res.data.beneficiary_documents !== undefined && res.data.beneficiary_documents.length > 0) {
        this.audio_file_path = res.data.beneficiary_documents?.find(item => item.file_type_id == 32)?.document_path || null
      }
      try {
        if (this.benData.age > 18) {

          if (res.data.beneficiary_documents != null && res.data.beneficiary_documents != '' && res.data.beneficiary_documents !== undefined && res.data.beneficiary_documents.length > 0) {
            this.id_proof_path = res.data.beneficiary_documents.find(item => item.file_type_id == 23)?.document_path;
          }

        } else {
          if (res.data.beneficiaryMemberGuardian != null && res.data.beneficiaryMemberGuardian?.guardian_documents != null && res.data.beneficiaryMemberGuardian?.guardian_documents !== undefined && res.data.beneficiaryMemberGuardian?.guardian_documents !== '' && res.data.beneficiaryMemberGuardian?.guardian_documents.length > 0) {
            this.id_proof_path = res.data.beneficiaryMemberGuardian.guardian_documents.find(item => item.file_type_id === 24)?.document_path || null;
          } else {
            this.id_proof_path = null;
          }

        }


      } catch {
        this.id_proof_path = null;
      }


    }, err => { this.toastr.error(err) });
  }

  getcenterByMem() {
    this.registrationService.getCenterByMemberID(this.depId).subscribe(res => {
      if (res.status == 'ACCEPTED') {
        this.center_id = res.data[0].center_id;
      } else {
        this.toastr.error(res.err);
      }

    })
  }
  getHubID() {
    let Id = this.center_id
    // this.registrationService.centersID
    if (Id == null || Id == undefined) {
      return;
    }
    this.registrationService.getbyCenterId(this.center_id).subscribe(res => {
      if (res.status == 'OK') {
        this.hubId = res.data?.hub_id
      } else {
        this.toastr.error(res.err);
      }
    })
  }
  //New lines added for the get POC referral hospital Drop Down 06-04-2022
  getReferralHospitals() {
    let hospId = this.hospTypeId
    let centerId = this.center_id;
    if (centerId != undefined) {
      this.registrationService.getPOCAllRefHosp(hospId, centerId).subscribe(res => {
        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0) {
            this.toastr.info('No Hospitals Found');
            this.refHospListNew = [];
          }
          else {
            this.refHospListNew = res.data;
          }
        }
      });
    } else {
      this.toastr.info('No Hospitals Found');
    }
    // this.filterUsers();

  }


  getmemDetails(id) {
    this.registrationService.getByID('beneficiarymemberdetails/getbyid?id=', id).subscribe(res => {
      this.registrationService.familyDetails = res.data;
      // console.log(' res.data Mem', res.data);
      this.memAge = res.data.age;
      sessionStorage.setItem('health_ida', res.data.health_id);
      sessionStorage.setItem('health_idn', res.data.health_id_number);
      if (res.data.beneficiaryMemberGuardian != null) {
        this.guardian_id = res.data.beneficiaryMemberGuardian.id;
      }
      // ===================================================
      if (res.data.beneficiary_member_documents) {
        this.audio_file_path = res.data.beneficiary_member_documents?.find(item => item.fileTypeId == 32)?.documentPath || null;
      }
      try {
        if (this.memAge > 18) {

          if (res.data.beneficiary_member_documents) {
            this.id_proof_path = res.data.beneficiary_member_documents.find(item => item.fileTypeId == 23)?.documentPath;
          }

        } else {
          if (res.data.beneficiaryMemberGuardian != null && res.data.beneficiaryMemberGuardian?.guardian_documents != null && res.data.beneficiaryMemberGuardian?.guardian_documents !== undefined && res.data.beneficiaryMemberGuardian?.guardian_documents !== '' && res.data.beneficiaryMemberGuardian?.guardian_documents.length > 0) {
            this.id_proof_path = res.data.beneficiaryMemberGuardian.guardian_documents.find(item => item.fileTypeId == 24)?.documentPath;
          } else {
            this.id_proof_path = null;
          }

        }


      } catch {
        this.id_proof_path = null;
      }







      // ==================================================================
      //  if(res.data.beneficiary_member_documents && res.data.age >= 18){
      //   this.audio_file_path3 = res.data.beneficiary_member_documents?.find(item => item.fileTypeId == 32)?.documentPath || null;
      //  }
      //  if(res.data.beneficiary_member_documents && res.data.age < 18){
      //   this.audio_file_path2 = res.data.beneficiary_member_documents?.find(item => item.fileTypeId == 32)?.documentPath || null;
      //  }
      //     if (res.data.age >= 18 && res.data.beneficiary_member_documents) {
      //       // this.id_proof_path3 = undefined
      //       this.id_proof_path3 = res.data.beneficiary_member_documents.find(item => item.fileTypeId == 23)?.documentPath;
      //     }
      //     if (res.data.age < 18 && res.data.beneficiaryMemberGuardian != null) {

      //       this.id_proof_path2 = res.data.beneficiaryMemberGuardian.guardian_documents.find(item => item.fileTypeId == 24)?.documentPath;
      //       }
      //     if (res.data.age >= 18 && this.id_proof_path3 == undefined && res.data.beneficiaryMemberGuardian != null) {
      //       this.id_proof_path2 = res.data.beneficiaryMemberGuardian.guardian_documents.find(item => item.fileTypeId == 24)?.documentPath;

      //     }
      // this.id_proof_path = res.data.beneficiary_documents.find(item => item.file_type_id == 23).document_path
    }, err => { this.toastr.error(err) });
  }
  viewID() {
    if (this.is_self) {
      if (this.benData.age >= 18) {
        this.imagePath = `${this.registrationService.viewFilePath}` + 'BENEFICIARY/' + this.benId + '/IdCard/' + this.id_proof_path;
      }
      if (this.benData.age < 18) {
        //GAURDIAN
        // this.imagePath = `${this.registrationService.viewFilePath}` + 'GUARDIAN/' + this.ben_guard_id + '/GuardianIdProof/' + this.id_proof_path;
        this.imagePath = `${this.registrationService.viewFilePath}` + 'GUARDIAN/' + this.ben_guard_id + '/GuardianIdProof/' + this.id_proof_path;

      }
      if (this.id_proof_path != undefined) {
        this.opnd = window.open(this.imagePath, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350,top=100,left=500');
      }


      else {
        this.toastr.info('No File Found')
      }
    }
    if (!this.is_self) {
      // this.getmemDetails(200);
      // console.log('Id Proof',);
      if (this.memAge >= 18) {
        this.imagePath = `${this.registrationService.viewFilePath}` + 'MEMBERDOCUMENTS/' + this.depId + '/IdCard/' + this.id_proof_path
      }
      if (this.memAge < 18) {
        // GAURDIAN
        // this.imagePath = `${this.registrationService.viewFilePath}` + 'GUARDIAN/' + this.guardian_id + '/GuardianIdProof/' + this.id_proof_path
        this.imagePath = `${this.registrationService.viewFilePath}` + 'GUARDIAN/' + this.guardian_id + '/GuardianIdProof/' + this.id_proof_path

      }

      if (this.id_proof_path != undefined) {
        this.opndn = window.open(this.imagePath, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350,top=100,left=500');
      }
      else {
        this.toastr.info('No File Found')
      }
    }


  }

  getDoctorDetails() {
    this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    // console.log('docData',this.docData);
    if (this.docData) {
      let qualificationName = this.qualificationsList.filter(f => f.id == this.docData.qualification_id)[0]?.qualification;
      let superDegree = this.superSpecialityDegreeList.filter(m => m.id == this.docData.super_qualification_id)[0]?.qualification;
      let adrs = this.docData.doctor_address.filter(f => f.display_on_prescription == true)[0]?.address;
      this.doc = {
        first_name: this.docData.first_name, last_name: this.docData.last_name, qualification: qualificationName,
        superspecialiy: superDegree, mci: this.docData.mci_number, address: adrs
      };
      this.pocForm.patchValue({
        doctor_id: this.docData,
        lab_id: this.docData.preferred_lab_id != null ? +this.docData.preferred_lab_id.id : '',
        pharmacy_id: this.docData.preferred_pharmacy_id != null ? +this.docData.preferred_pharmacy_id.id : '',
      });
    }
  }
  audioConsent() {

    if (this.is_self) {

      // if (this.benData.age >= 18) {
      const url = sessionStorage.getItem('tenantUrl')
      this.audioPath = `${url}` + '/vidmedfiles/' + 'BENEFICIARY/' + this.benId + '/RecordingAudioConsultation/' + this.audio_file_path;
      // }
      // if (this.benData.age < 18) {
      //GAURDIAN
      // this.audioPath = `${this.registrationService.viewFilePath}` + 'GUARDIAN/' + this.ben_guard_id + '/RecordingAudioConsultation/' + this.audio_file_path;
      //   this.audioPath = `${this.registrationService.viewFilePath}` + 'GAURDIAN/' + this.ben_guard_id + '/RecordingAudioConsultation/' + this.audio_file_path;

      // }
      // if (this.audio_file_path != undefined) {
      //   window.open(this.imagePath, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350,top=100,left=500');
      // }


      // else {
      //   this.toastr.info('No File Found')
      // }
      this.playlist = [
        {
          title: 'Audio Consent',
          link: this.audioPath
        },

      ];
      this.playAudio(this.audioPath)
    }
    if (!this.is_self) {

      // if (this.memAge >= 18) {
      this.audioPath = `${this.registrationService.viewFilePath}` + 'MEMBERDOCUMENTS/' + this.depId + '/RecordingAudioConsultation/' + this.audio_file_path
      // }
      // if (this.memAge < 18 || this.id_proof_path3 == undefined) {
      //GAURDIAN
      // this.audioPath = `${this.registrationService.viewFilePath}` + 'GUARDIAN/' + this.guardian_id + '/RecordingAudioConsultation/' + this.audio_file_path2
      //   this.audioPath = `${this.registrationService.viewFilePath}` + 'GAURDIAN/' + this.guardian_id + '/RecordingAudioConsultation/' + this.audio_file_path2

      // }

      // if (this.id_proof_path2 != undefined || this.id_proof_path3 != undefined) {
      //   window.open(this.imagePath, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350,top=100,left=500');

      // }
      // else {
      //   this.toastr.info('No File Found')
      // }
      this.playlist = [
        {
          title: 'Audio Consent',
          link: this.audioPath
        },

      ];
      this.playAudio(this.audioPath)

    }

  }
  playAudio(audioPath) {
    // const blob = new Blob([this.audioPath], { type:'audio/mp3' });
    // const url = window.URL.createObjectURL(blob);
    // const anchor = document.createElement('a');
    // anchor.download = 'audio_recording'+ new Date();
    // anchor.href = url;
    // document.body.appendChild(anchor)
    // anchor.click();
    // document.body.removeChild(anchor)

    // const blob:any = new Blob([], { type:'audio/mp3'});
    const url = audioPath
    window.open(url);

  }

  openModal(item: any = null) {
    // if (!this.isOffLinePOC()) {
    //   document.getElementById('switch').click();
    // }
    let ar = [];
    if (Object.keys(this.pocForm.value.covid19).length == 0)
      ar.push(item);
    else
      ar.push(this.pocForm.value.covid19);
    const modalRef = this.modalService.open(CovidComponent, { scrollable: true, size: 'xl', windowClass: 'modal-zindex' });
    modalRef.componentInstance.title = 'Covid 19';
    modalRef.componentInstance.isPOC = true;
    modalRef.componentInstance.history = ar;
    modalRef.componentInstance.consultData = ar;
    modalRef.result.then(result => {
      if (result && result != 'Close click')
        this.pocForm.patchValue({ covid19: result });

    }, (reason) => { });
  }

  openViewFileModal(type: any) {
    this.changeDetection.detach();
    if (type === 'ViewFiles') {
      const modalRef = this.modalService.open(ViewFileComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = 'View Files';
      modalRef.componentInstance.isPocScreen = true;
      modalRef.componentInstance.patientInteractionId = +this.pocForm.get('patient_interaction_id').value;
      modalRef.componentInstance.docId = this.docData.id;
      modalRef.componentInstance.is_self = this.is_self;
      modalRef.componentInstance.benificiaryId = this.is_self ? this.benId : parseInt(sessionStorage.getItem('callingpatient_id_fm'));
      modalRef.componentInstance.insuranceFilesList = [];

      modalRef.result.then(result => {
        this.changeDetection.reattach();
        // here call Table load API
      }, (reason) => { });
    } else {
      const modalRef = this.modalService.open(ViewFileComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = 'Insurance Files';
      modalRef.componentInstance.isPocScreen = true;
      modalRef.componentInstance.patientInteractionId = +this.pocForm.get('patient_interaction_id').value;
      modalRef.componentInstance.docId = this.docData.id;
      modalRef.componentInstance.is_self = this.is_self;
      modalRef.componentInstance.insuranceFilesList = this.benData?.insurance_plan_details?.beneficiary_document_details;
      modalRef.componentInstance.benificiaryId = this.is_self ? this.benId : parseInt(sessionStorage.getItem('callingpatient_id_fm'));
      modalRef.result.then(result => {
        this.changeDetection.reattach();
        // here call Table load API
      }, (reason) => { });
    }

  }
  getallConsultations() {
    this.masterService.getAll(`getallconsultations?TENANT_ID=${this.orgId}`).subscribe(res => {
      if (res.status === 'OK') {
        this.ConsultationList = res.data
        // this.pocForm.patchValue({
        //   type_of_consultation: this.ConsultationList[-1].consultationType
        // })

      } else {
        this.toastr.error(res.message);
      }
    })
  }
  getAllMaster() {
    let currentTeanant: any = sessionStorage.getItem('currentTenant')

    let drId = JSON.parse(sessionStorage.getItem('DoctorDetails'))?.id ?? null;
    let labQueryString = `LabServices/getinvestigationbydoctorid?doctorId=${drId}&TENANT_ID=${currentTeanant}`
    const chiefComplaint = this.registrationService.postChieflist(drId); // this.masterService.getAll('getallchiefcomplaints');
    const diagnosismedicine = this.registrationService.postPDlist(drId); // this.masterService.getAll('getalldiagnosis');

    const investigations = this.labService.getinvestigationbydoctorid(labQueryString);
    //const investigations = this.registrationService.postIVglist(drId);  // this.masterService.getAll('getallinvestigations');
    const drugtypes = this.registrationService.postDruglist(drId); // this.masterService.getAll('getalldrugtypes');
    // const drugnames = this.masterService.getAll('getalldrugs');
    // below line commented for performance 06-07-2022
    // const diagnosis = this.masterService.getAll('getalldiagnosis');
    const directions = this.masterService.getAll('getalldirections');
    const instruction = this.masterService.getAll('getallinstruction');
    const qualifications = this.masterService.getAll('getallqualifications');
    const specialization = this.masterService.getAll('getallspecialization');
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    const genders = this.masterService.getAll('getallgenders');
    const labsList = this.registrationService.getAll('lab/newgetalllabregistrations');
    const phrmcyList = this.registrationService.getByAllPharmas();
    const stateList = this.masterService.getAll('getallstates');
    //Previously code comented below 06-07-2022
    //  forkJoin([chiefComplaint, diagnosis, drugtypes, investigations, directions, instruction, qualifications,

    forkJoin([chiefComplaint, diagnosismedicine, drugtypes, investigations, directions, instruction, qualifications,
      specialization, superSpecialityDegree, genders, diagnosismedicine, labsList, phrmcyList, stateList]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available', 'Plan of Care');
              else {
                const assignList = {
                  '0': () => { this.lstAllCC = list.data, this.moreCC = this.lstAllCC.doctor_cheif_compaints.length > 0, this.chiefComplaintList = !this.moreCC ? this.lstAllCC.master_cheif_compaints : this.lstAllCC.doctor_cheif_compaints }, // list.data,
                  //below line commented for for performance 06-07-2022
                  // '1': () => this.diagnosislList = list.data,
                  '1': () => this.diagnosislList = list.data.master_provisional_diagnosis,
                  '2': () => { this.lstAllDN = list.data, this.moreDN = this.lstAllDN.doctor_drug_types.length > 0, this.drugtypesList = !this.moreDN ? this.lstAllDN.master_drug_types : this.lstAllDN.doctor_drug_types }, // list.data,
                  '3': () => { this.lstAllIN = list.data, this.moreIN = this.lstAllIN.doctor_investigations.length > 0, this.investigationsList = !this.moreIN ? this.lstAllIN.master_investigations : this.lstAllIN.doctor_investigations }, // list.data,
                  '4': () => this.directionsList = list.data,
                  '5': () => this.instructionList = list.data,
                  '6': () => this.qualificationsList = list.data,
                  '7': () => this.specializationList = list.data,
                  '8': () => this.superSpecialityDegreeList = list.data,
                  '9': () => this.gendersList = list.data,
                  '10': () => { this.lstAllPD = list.data, this.morePD = this.lstAllPD.doctor_provisional_diagnosis.length > 0, this.diagnosysgroupList = !this.morePD ? this.lstAllPD.master_provisional_diagnosis : this.lstAllPD.doctor_provisional_diagnosis }, // list.data,
                  /* {
                    if (this.isLcl) { 
                      let dgnsData: any[] = list.data;
                      console.log(this.addOthersPD(dgnsData)); // dgnsData = dgnsData.unshift({ id: '0', diagnosis_name: 'OTHER', is_active: true });
                      this.diagnosysgroupList = dgnsData;
                    }
                    else
                      this.diagnosysgroupList = list.data;
                  }, */
                  '11': () => this.lstLabs = list.data,
                  '12': () => this.lstPhmcy = list.data,
                  '13': () => this.lstStates = list.data,
                }

                assignList[ind]();
              }
            }
          });
          this.totalData = this.chiefComplaintList;
          this.chiefComplaintList.sort((a, b) => (a.chief_complaint < b.chief_complaint ? -1 : 1));
          // this.chiefComplaintList = this.totalData.filter(n => this.totalData.indexOf(n) < 5);
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.qualificationsList.sort((a, b) => a.id - b.id), this.setTemplateValues(), this.setProvisionalValues(), this.getDoctorDetails(); //this.userData.id
        },
      });
  }



  LoadRegionsbyStateId(stateId: any) {
    this.lstRegns = [], this.lstDist = [], this.lstTelgLab = [], this.lstTelgPhrm = [], this.labAddrs = '', this.phrmcyAddrs = '',
      $('#labPhrmPerc').val(''), $('#labsPerc').val('');
    if (this.lstStates.length > 0 && stateId) {
      this.masterService.getAll('getregionsbystateid?stateId=' + stateId).subscribe(r => {
        if (r)
          this.lstRegns = r.data;
      });
    }
  }

  LoadDistbyRegion(rgnId: any) {
    this.lstDist = [], this.lstTelgLab = [], this.lstTelgPhrm = [], this.labAddrs = '', this.phrmcyAddrs = '',
      $('#labPhrmPerc').val(''), $('#labsPerc').val('');
    if (rgnId) {
      this.masterService.getAll('getdistrictsbyregionid?regionId=' + rgnId).subscribe(r => {
        if (r)
          this.lstDist = r.data;
      })
    }
  }
  LoadLabPharmabyDist() {

    const labsList = this.registrationService.getAll2('lab/getlabbydistrictid?districtId=' + '');
    const phrmList = this.registrationService.getAll2('pharmacy/getpharmacybydistrictid?districtId=' + '');
    forkJoin([labsList, phrmList]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available', 'Plan of Care');
            else {
              const assignList = {
                '0': () => this.lstTelgLab = list.data,
                '1': () => this.lstTelgPhrm = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { },
    });

  }


  onLabsChng(labId: any) {
    $('#labsPerc').val(''), this.labAddrs = '';
    if (labId) {
      let labData = this.lstTelgLab.filter(f => f.id === +labId)[0],
        sId = labData?.state_id,
        rId = labData?.id,
        dId = labData?.district_id,
        labName = labData.lab_name,
        state = this.lstStates.filter(f => f.id === +sId)[0].state_name,
        //region = this.lstRegns.filter(f => f.id === +rId)[0]?.region_name,
        //district = this.lstStates.filter(f => f.districts.id === +dId)[0]?.district_name,
        district = this.lstStates.filter(f => f.id === +sId)[0].districts.filter(d => d.id == +dId)[0].district_name,
        // state = this.lstStates.filter(f => f.id === +sId)[0]?.state_name, 
        // region = this.lstRegns.filter(f => f.id === +rId)[0]?.region_name,
        // district = this.lstStates.filter(f => f.districts.id === +dId)[0]?.district_name, 
        cntct = labData.lab_conatct_no,
        adrs = labData.address,
        prsnName = labData.contact_person_name + ' ' + labData.contact_person_last_name, pin = labData.pin_code;
      $('#labsPerc').val(labData.discount ?? 0);
      this.labAddrs = `${labName}, ${prsnName}, ${adrs}, ${district},${state}, ${pin}, Lab Contact: ${cntct}`;
    }
  }

  onPhrmcChng(phrmId: any) {
    $('#labPhrmPerc').val(''), this.phrmcyAddrs = '';
    if (phrmId) {
      let phrmData = this.lstTelgPhrm.filter(f => f.id === +phrmId)[0];
      let sId = phrmData?.state_id;
      //let rId =phrmData?.id;
      let dId = phrmData?.district_id;
      let phrmName = phrmData.pharmacy_name,
        state = this.lstStates.filter(f => f.id === +sId)[0].state_name,
        //region = this.lstRegns.filter(f => f.id === +rId)[0]?.region_name,
        //district = this.lstStates.filter(f => f.districts.id === +dId)[0]?.district_name,
        district = this.lstStates.filter(f => f.id === +sId)[0].districts.filter(d => d.id == +dId)[0].district_name,
        cntct = phrmData.contact_person_mobile,
        adrs = phrmData.address,
        prsnName = phrmData.contact_person_name + ' ' + phrmData.contact_person_last_name, pin = phrmData.pin_code;
      $('#labPhrmPerc').val(phrmData.discount ?? 0);
      this.phrmcyAddrs = `${phrmName}, ${prsnName}, ${adrs}, ${district},${state}, ${pin}, Pharmacy Contact: ${cntct}`;
    }
  }

  LoadMO(type: string) {
    if (type == 'CC')
      this.chiefComplaintList = [...(this.txtMoreCC == 'Show More' ? this.lstAllCC.master_cheif_compaints :
        this.lstAllCC.doctor_cheif_compaints)], this.txtMoreCC = this.txtMoreCC == 'Show More' ? 'Show Less' : 'Show More';
    else if (type == 'PD')
      this.diagnosysgroupList = [...(this.txtMorePD == 'Show More' ? this.lstAllPD.master_provisional_diagnosis :
        this.lstAllPD.doctor_provisional_diagnosis)], this.txtMorePD = this.txtMorePD == 'Show More' ? 'Show Less' : 'Show More';
    else if (type == 'IN')
      this.investigationsList = [...(this.txtMoreIN == 'Show More' ? this.lstAllIN.master_investigations :
        this.lstAllIN.doctor_investigations)], this.txtMoreIN = this.txtMoreIN == 'Show More' ? 'Show Less' : 'Show More';
    else
      this.drugtypesList = [...(this.txtMoreDN == 'Show More' ? this.lstAllDN.master_drug_types :
        this.lstAllDN.doctor_drug_types)], this.txtMoreDN = this.txtMoreDN == 'Show More' ? 'Show Less' : 'Show More';
  }

  setTemplateValues() {
    if (this.chiefComplaintList?.length > 0) {
      ['Cough', 'Pain or Discomfort', 'Recurrent fever', 'Swelling', 'Other'].forEach((e, i) => {
        let ccData = this.chiefComplaintList.filter(f => f.chief_complaint.trim().toLowerCase() == e.trim().toLowerCase()).length;
        if (i == 0)
          this.ccCgh = ccData > 0;
        else if (i == 1)
          this.ccPrd = ccData > 0;
        else if (i == 2)
          this.ccRef = ccData > 0;
        else if (i == 3)
          this.ccSwl = ccData > 0;
        else
          this.ccOther = ccData > 0;
      });
    }
  }

  // setProvisionalValues() {
  //   if (this.diagnosysgroupList.length > 0) {
  //     ['Other'].forEach((e, i) => {
  //       let ccData = this.diagnosysgroupList.filter(f => f.diagnosis_name.trim().toLowerCase() == e.trim().toLowerCase()).length;
  //       if (i == 0)
  //         this.pdOther = ccData > 0;
  //     });
  //   }
  // }

  // sushma 22-07-2022 type of diagnosis values based on search

  setProvisionalValues() {
    if (this.typeofDiagnosisLst.length > 0) {
      ['Other'].forEach((e, i) => {
        let ccData = this.typeofDiagnosisLst.filter(f => f.diagnosis_name.trim().toLowerCase() == e.trim().toLowerCase()).length;
        if (i == 0)
          this.pdOther = ccData > 0;
      });
    }
  }

  addOthersPD(lstPD: any[]) {
    return lstPD.push({ id: '0', diagnosis_name: 'OTHER', is_active: true });
  }

  openVitalFileModal() {
    const modalRef = this.modalService.open(VitalsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.is_self = this.is_self;
    modalRef.componentInstance.bdId = this.is_self ? this.benId : this.depId;
    modalRef.result.then(result => {
      // here call Table load API
    }, (reason) => { });
  }




  private scrollToFirstInvalidControl() {

    if (this.selectedItemscc.length == 0) {
      const element = document.getElementById('pills-home-tab');
      element.click();
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        "form .ng-invalid"
      );

      firstInvalidControl.parentElement.scrollIntoView({ behavior: 'smooth' })

      fromEvent(window, "scroll")
        .pipe(
          debounceTime(100),
          take(1)
        )
        .subscribe(() => firstInvalidControl.focus());
    }
    else if (this.pocForm.get('allergy.allergies').value == '') {
      const element = document.getElementById('pills-profile-tab');
      element.click();
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        "form .ng-invalid"
      );


      // var focusEle = setInterval(() => {
      firstInvalidControl.parentElement.scrollIntoView({ behavior: 'smooth' })

      fromEvent(window, "scroll")
        .pipe(
          debounceTime(100),
          take(1)
        )
        .subscribe(() => firstInvalidControl.focus());
      // }, 100)

      // setTimeout(() => {
      //   clearInterval(focusEle)
      // }, 1500)

    }
  }

  onPreview(event) {

    event.preventDefault();
    this.submitted = true;
    this.tableRowCheckValidations();

    // if (this.pocForm.invalid || this.showTestField==1) {
    //     if(this.pocForm.value.cc_brief_history['othersifany']=='' || this.pocForm.value.cc_brief_history['othersifany']==null){
    //       this.toastr.info('Please Enter Other Chief Complaints Fields');
    //       return;
    //     }
    // if(this.enableConsultationTypeInPOC){
    // this.pocForm.get('type_of_consultation').clearValidators();
    // }



    if (this.pocForm.invalid) {
      if (this.showTestField == 1 && this.pocForm.value.cc_brief_history['othersifany'] == '' || this.pocForm.value.cc_brief_history['othersifany'] == null) {
        this.toastr.info('Please Enter Other Chief Complaints Fields');
        return;
      }
      //23-02-2023

      this.toastr.info('Please Enter All Mandatory Fields');
      this.pocForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      return;
    }
    else {
      if (this.pdSlctd.length == 0 && this.enableProvisionalDiagnosis) {
        this.toastr.info('Please Provide provisional Diagnosis');
        return;
      }
      if (this.enableConsultationTypeInPOC) {
        let type = this.pocForm.value.type_of_consultation;
        if (type == '') {
          this.toastr.info('Please Provide Type of Consultation');
          return;
        }
      }


      // if (this.pocForm.value.drugs.instruction != '' && this.getRowFormControls.value.length > 0)
      if (this.pocForm.value.instruction != '' && this.getRowFormControls.value.length > 0)
        this.getRowFormControls.value.forEach((e, i) => {
          // this.getRowFormControls.at(i).patchValue({ instruction: this.pocForm.value.drugs.instruction });
          this.getRowFormControls.at(i).patchValue({ instruction: this.pocForm.value.instruction });
          // e.instruction = this.pocForm.value.drugs.instruction;
          e.instruction = this.pocForm.value.instruction;
        });
      this.provDgns = '';
      if (this.pocForm.value.diagnosis?.provisionalDiagnosis.length > 0) {
        this.pocForm.value.diagnosis.provisionalDiagnosis.forEach(e => {
          this.provDgns += this.provDgns == '' ? e.diagnosis_name : `,${e.diagnosis_name}`;
        });
      }
      if (this.getRowFormControls.controls.length == 0) { // && !this.yesContinue
        document.getElementById('btncontinueModal').click();
        return;
      }
      this.openPrescriptionModal();

    }

  }

  // leftMenu() {
  //   $(document).ready(function () {
  //     const btnOffsetTop = $('.videoposition')[0].offsetTop;
  //     const btnHeight = $('.videoposition')[0].scrollHeight;
  //     $(window).scroll(function () {
  //       if (btnOffsetTop + btnHeight <= window.pageYOffset) {
  //         $('.videoposition').addClass('videofixed');
  //       } else {
  //         $('.videoposition').removeClass('videofixed');
  //       }
  //     });
  //   });
  //   $(() => {
  //     $('#opener').on('click', function () {
  //       var panel = $('#slide-panel');
  //       if (panel.hasClass("visible")) {
  //         panel.removeClass('visible').animate({ 'margin-left': '-230px' });
  //       } else {
  //         panel.addClass('visible').animate({ 'margin-left': '0px' });
  //       }
  //       return false;
  //     });
  //   });
  // }

  showHideVideo() {
    // const fromPatient = JSON.parse(sessionStorage.isPatient || 'false');

    if (this.isOffLinePOC() == false) {
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
      dragElement.style.display = (dragElement.style.display == 'none') ? 'block' : 'none';
    }
  }

  isOffLinePOC() {
    return JSON.parse(sessionStorage.isPatient || false)
  }
  getAllHospTypes() {
    this.masterService.getAll('getallahospitaltypes').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.HospTypeList = res.data;
      }
    });
  }
  onRefStatusChange(e: any) {
    this.refStatus = parseInt(e);
  }
  onHospTypeChange(e: any) {
    if (this.refhospId) {
      this.selectedRefHp = []
    }

    this.statusdata = this.hospTypeId = parseInt(e);
    this.hospitalType = this.HospTypeList.find(a => a.id == this.hospTypeId).hospital_type;
    this.hospitalName = null;
    // this.getrefHospitals();
    this.getReferralHospitals();
  }

  getrefHospitals() {
    let hospId = this.hospTypeId
    let hubId = this.hubId;
    if (hubId != undefined) {
      this.registrationService.getAllRefHosp(hospId, hubId).subscribe(res => {
        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0) {
            this.toastr.info('No Hospitals Found');
            this.refHospList = [];
          }
          else {
            this.refHospList = res.data;
          }
        }
      });
    } else {
      this.toastr.info('No Hospitals Found');
    }

  }
  onHospDeSelect(item: any) {
    this.refhospitalName = null;
  }

  onHospChange(item) {

    this.refhospId = parseInt(item.id);
    // this.hospitalName=this.refHospList.find(a=>a.id==this.refhospId).hospital_name;
    this.refhospitalName = this.refHospListNew.find(a => a.id == this.refhospId).hospital_name;
  }
  leftMenu() {
    $(document).ready(function () {
      $(".drag").draggable({ containment: "window", scroll: true, });
      // const btnOffsetTop = $('.videoposition')[0].offsetTop;
      // const btnHeight = $('.videoposition')[0].scrollHeight;
      // $(window).scroll(function () {
      //   if (btnOffsetTop + btnHeight <= window.pageYOffset) {
      //     // $('.videoposition').addClass('videofixed');
      //     // $(".videoposition").draggable({ containment: "window", scroll: false });
      //   } else {
      //     // $('.videoposition').removeClass('videofixed');
      //   }
      // });
    });
    $(() => {
      $('#opener').on('click', function () {
        var panel = $('#slide-panel');
        if (panel.hasClass("visible")) {
          panel.removeClass('visible').animate({ 'margin-left': '-253px' });
        } else {
          panel.addClass('visible').animate({ 'margin-left': '-25px' });
        }
        return false;
      });
    });
  }

  changeMenu() {
    this.homeComponent.isDoctor = true;
    const value = {
      screen_id: 72,
      module_id: 6
    }
    this.router.navigate(['/home/consultation/cn']).then(r => {
      setTimeout(() => {
        this.homeComponent.sidemenu(value, 'another')
      }, 100);
      stopVideo();
    })


  }


  onCCButtonSelect(val: String) {

    let data = this.chiefComplaintList.find(f => f.chief_complaint == val);
    if (this.selectedList.filter(f => f.id == data.id).length == 0) {
      this.selectedList.push(data);
      if (data.chief_complaint == 'Other') {
        this.showTestField = 1;
        // this.pocForm.value.get('othersifany').addValidators(Validators.required); 
        //this.pocForm.controls.cc_brief_history['othersifany'].setValidators('',[Validators.required]);
      }
      else {
        //this.pocForm.controls.cc_brief_history['othersifany'].clearValidators();
        // this.pocForm.value.get('othersifany').clearValidators(); 
      }
    } else {
      this.selectedList.forEach((x, i) => { if (x.id == data.id) this.selectedList.splice(i, 1); });
    }
    this.selectedList = [...this.selectedList];
    this.pocForm.patchValue({ cc_brief_history: { chiefComplaints: this.selectedList } });

  }

  onItemDeSelect(item: any) {
    let data = this.selectedList;
    data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
    this.selectedList = [...data];
    if (this.selectedList.some(a1 => a1.chief_complaint == 'Other')) {
      // this.pocForm.controls.cc_brief_history['othersifany'].setValidators([Validators.required]);
      this.showTestField = 1;
    }
    else {
      this.showTestField = 0;
      // this.pocForm.controls.cc_brief_history['othersifany'].clearValidators();

    }

  }

  onItemSelect(item: any) {
    if (this.selectedList.filter(f => f.id == item.id).length == 0)
      this.selectedList.push(item);
    this.selectedList = [...this.selectedList];
    if (this.selectedList.some(a1 => a1.chief_complaint == 'Other')) {
      //   this.pocForm.controls.cc_brief_history['othersifany'].setValidators([Validators.required]);
      this.showTestField = 1;
    } else {
      // this.pocForm.controls.cc_brief_history['othersifany'].clearValidators();
      this.showTestField = 0;
    }

  }
  onPdButtonSelect(val: any) {
    let data = this.diagnosysgroupList.find(f => f.diagnosis_name == val);
    if (this.pdSlctd.filter(f => f.id == data.id).length == 0) {
      this.pdSlctd.push(data);
      if (data.diagnosis_name == 'Other') {
        this.isShowPDOthr = true;
      }
      else {
        this.isShowPDOthr = false;
      }
    } else {
      this.pdSlctd.forEach((x, i) => { if (x.id == data.id) this.pdSlctd.splice(i, 1); });
    }
    this.pdSlctd = [...this.pdSlctd];
    this.pocForm.patchValue({ diagnosis: { provisionalDiagnosis: this.pdSlctd } });
  }

  onSelectPD(item: any, type: string) {
    if (type == 's') {
      this.typeOfdiagnosis = '';
      const element = document.getElementById('txtdiagno');
      element.focus();
      if (this.pdSlctd.filter(f => f.id == item.id).length == 0)
        this.pdSlctd.push(item);
      const expected = new Set();
      this.checkedLst = this.pdSlctd.filter(item => !expected.has(JSON.stringify(item)) ? expected.add(JSON.stringify(item)) : false);
    }
    else
      this.pdSlctd.forEach((x, i) => { if (x.id == item.id) this.pdSlctd.splice(i, 1); });
    this.pdSlctd = [...this.pdSlctd], this.pocForm.patchValue({ diagnosis: { provisionalDiagnosis: this.pdSlctd } });
    const expected = new Set();
    this.checkedLst = this.pdSlctd.filter(item => !expected.has(JSON.stringify(item)) ? expected.add(JSON.stringify(item)) : false);
    this.isShowPDOthr = this.pdSlctd.length > 0 ? this.pdSlctd.filter(f => f.diagnosis_name.toLowerCase() == 'other').length > 0 : false;
  }

  onDrgSlct(item: any, ctrl: string) {
    this.drugSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.drugNameSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    if (ctrl == 'dt') {
      item['id'] = this.drugtypesList.find(a => a.drug_type == item.drug_type).id
      this.drug_list = [], this.dN = [], this.pocForm.patchValue({ drugs: { drugType: item.id, drugName: '' } }), this.drugnamesList = [];
      if (Object.keys(this.drugtypesList.filter(f => f.id == item.id)[0]).filter(f => f == 'drug_names').length == 0) {
        if (this.disableSearchByDrugName) {
          this.LoadDrugsbyDTId(item.id);
        } else {
          this.drugId = item.id;
          //  this.LoadDrugsbySearchDTId(item.id)
        }

      }

      else {
        // this.drugNameSettings = { singleSelection: true, idField: 'id', textField: 'name', selectAllText: 'Select All',
        //   unSelectAllText: 'Deselect All', itemsShowLimit: 1, enableCheckAll: false, allowSearchFilter: true },
        this.drug_list = [...this.drugtypesList.filter(f => f.id == item.id)[0].drug_names];
      }
    }
    else
      this.pocForm.patchValue({ drugs: { drugName: item.id } });
  }

  onDrgDeSlct(ctrl: string) {
    if (ctrl == 'dt')
      this.pocForm.patchValue({ drugs: { drugType: '', drugName: '' } }), this.drug_list = [], this.drug_list = [...this.drug_list], this.dN = [];
    else
      this.pocForm.patchValue({ drugs: { drugName: '' } });
  }

  onLabPhrmcySlct(item: any, ctrl: string) {
    if (ctrl == 'p')
      this.pocForm.patchValue({ phrmcy: item.id });
    else
      this.pocForm.patchValue({ lab: item.id });
  }

  onLabPhrmcyDeSlct(ctrl: string) {
    if (ctrl == 'p')
      this.pocForm.patchValue({ phrmcy: '' });
    else
      this.pocForm.patchValue({ lab: '' });
  }

  LoadDrugsbyDTId(dtId: any) {
    // below line commented for new API change for drug add 14-04-2023
    // this.masterService.searchbyIdMasters(`drugtypemapping/getdrugbydrugtypeid?drugTypeId=${dtId}`).subscribe(res => {

    this.registrationService.getDrugsListNew(dtId).subscribe(res => {
      if (res) {
        this.drugnamesList = res.data;
        if (this.drugnamesList.length > 0)


          // below line commented for new API change for drug add 14-04-2023
          //   this.drugnamesList.forEach(e => { this.drug_list.push({ id: e.drug_id.id, drug_name: e.drug_id.drug_name }); });
          // this.drug_list = [...this.drug_list];
          this.drugnamesList.forEach(e => {
            this.drug_list.push({ id: e.id, drug_name: e.drug_name });
            this.completeDrugList.push(e);
          });
        this.completeDrugList = [... this.completeDrugList];
        this.drug_list = [...this.drug_list];
      }
    });
  }

  //New Method added for search drug name 07-07-2023
  LoadDrugsbySearchDTId(dtId: any) {
    this.drugnamesList = [];
    this.registrationService.getDrugsListBySearch(dtId, this.searchByDrugName).subscribe(res => {
      if (res) {
        this.drugnamesList = [];
        this.drugnamesList = res.data;
        // console.log('drugnamesList',this.drugnamesList)
        if (this.drugnamesList.length > 0)
          this.drugnamesList.forEach(e => {
            this.drug_list.push({ id: e.id, drug_name: e.drug_name });
            this.completeDrugList.push(e);
          });

        this.completeDrugList = [... this.completeDrugList];
        this.drug_list = [...this.drug_list];
      }
    });
  }
  // end line drug name

  onPHButtonSelect(txt: String) { //past history
    let [value, data] = [this.pocForm.value.other_history.history, (this.pocForm.value.other_history.history).toUpperCase().split(',').map(item => item.trim())];
    if (!data.includes(txt.toUpperCase()))
      this.pocForm.patchValue({ other_history: { history: value == '' ? txt : (value + ', ' + txt).replace(/^,/, '') } });
    // else {
    //   let cc = '';
    //   data.forEach((e,i) => { if (e.trim().toUpperCase() == txt.trim().toUpperCase()) data.splice(i, 1); });
    //   if(data.length > 0)
    //   data.forEach((e,i) => {  cc = (i == 0) ? e : (cc + ',' + e.trim()); });
    //   this.pocForm.patchValue({ other_history: { history: cc !='' ? cc.replace(/^,/, '') : ''} });
    // }
  }

  // onPDButtonSelect(txt: String) { // Provisional Diagnosis
  //   let [value, data] = [this.pocForm.value.diagnosis.provisionalDiagnosis, (this.pocForm.value.diagnosis.provisionalDiagnosis).toUpperCase().split(',').map(item => item.trim())];
  //   if (!data.includes(txt.toUpperCase()))
  //     this.pocForm.patchValue({ diagnosis: { provisionalDiagnosis: value == '' ? txt : (value + ', ' + txt).replace(/^,/, '') } });
  // }

  onAllergyButtonSelect(txt: String) {
    let [value, data] = [this.pocForm.value.allergy.allergies, (this.pocForm.value.allergy.allergies).toUpperCase().split(',').map(item => item.trim())];
    if (!data.includes(txt.toUpperCase()))
      this.pocForm.patchValue({ allergy: { allergies: value == '' ? txt : (value + ', ' + txt).replace(/^,/, '') } });
    // else {
    //   let cc = '';
    //   data.forEach((e,i) => { if (e.trim().toUpperCase() == txt.trim().toUpperCase()) data.splice(i, 1); });
    //   if(data.length > 0)
    //   data.forEach((e,i) => {  cc = (i == 0) ? e : (cc + ',' + e.trim()); });
    //   this.pocForm.patchValue({ allergy: { allergies: cc !='' ? cc.replace(/^,/, '') : ''} });
    // }
  }

  tableRowForm(): FormGroup {
    return this.formBuilder.group({
      type: this.medType,
      id: this.drugs_id,
      name: this.medName,
      mrng: [false],
      aftr: [false],
      evng: [false],
      night: [false],
      sos: [false],
      dosage: ['', Validators.required],
      relfood: ['', Validators.required],
      period: ['', Validators.required],
      // count: [{ value: '', disabled: true }, Validators.required],
      count: [{ value: '', disabled: false }, Validators.required],
      remarks: [''],
      instruction: [''],
      agentStock: [''],
    });
  }

  onValChng(index: number, val: any, ctrl: string) {
    if (val != '') {
      if (+val == 0 && ctrl == 'dosage')
        this.getRowFormControls.at(index).patchValue({ dosage: '' });
      if (+val == 0 && ctrl == 'count')
        this.getRowFormControls.at(index).patchValue({ count: '' });
    }
  }


  addRow() {
    if (!this.isFeature) {
      let f = this.pocForm.value.drugs; this.medName = ''; this.medType = '';
      if (this.drugnamesList == undefined) {
        this.drugnamesList = [];
      }
      if (this.drugnamesList.length > 0) {
        // below line commented for new API change for drug add 14-04-2023
        // this.medName = f.drugName != '' ? this.drugnamesList.filter(e => e.drug_id.id == f.drugName)[0]?.drug_id.drug_name : '';
        // this.medType = f.drugType != '' ? this.drugtypesList.filter(e => e.id == f.drugType)[0]?.drug_type : '';
        this.medName = f.drugName != '' ? this.drugnamesList.filter(e => e.id == f.drugName)[0]?.drug_name : '';
        this.medType = f.drugType != '' ? this.drugtypesList.filter(e => e.id == f.drugType)[0]?.drug_type : '';

      }
      else {
        this.medType = f.drugType != '' ? this.drugtypesList.filter(e => e.id == f.drugType)[0]?.drug_type : '';
        // this.medName = f.drugName != '' ? this.drugtypesList.filter(e => e.id == f.drugType)[0]?.drug_names[0].drug_name : '';
        //Uma
        this.medName = f.drugName != '' ? this.drugtypesList.filter(e => e.id == f.drugType)[0].drug_names.filter(d => d.id == f.drugName)[0].drug_name
          : '';
      }

      if (this.medType == '' && this.medName == '' && f.otherDrug == '') {
        this.toastr.info('Please Select Drug Type and Drug Name'); // or Other Drug
        return;
      }
      else if (this.medName == '' && f.otherDrug == '') {
        this.toastr.info('Please Select Drug Name'); // or Other Drug
        return;
      }
      else if (this.medType == '') {
        this.toastr.info('Please Select Drug Type');
        return;
      }
      if (this.medName == '')
        this.medName = f.otherDrug;

      this.pocForm.patchValue({ drugs: { drugType: '', drugName: '', otherDrug: '' } }), [this.dT, this.dN] = ['', []];

      if (this.getRowFormControls.value.filter(f => f.name == this.medName).length > 0) {
        this.toastr.info('Drug is already selected');
        return;
      }

      const control = this.pocForm.get('prescribed_medications') as FormArray;
      if(this.enableHumanMMU){
        control.push(this.tableRowForm());
      }else{
        control.push(this.tableRowForm());
      }
      this.viewAgentStock();
    }
    else if (this.isFeature) {
      let f = this.pocForm.value.drugs; this.medName = ''; this.medType = '';
      // let srhid = this.srhLst.filter(f=>f.id);
      // let package_quantity =this.selectedItems.filter(f=>f.id == srhid)
      this.selectedsearchItems?.forEach(f => {
        this.medName = f.drug_name,
          this.drugs_id = f.id
        // this.qa
      })
      this.medType = this.srhLst.filter(f => f.id == this.selectedsearchItems[0]?.id)[0]?.type;
      let drug_id = this.srhLst.filter(f => f.id == this.selectedsearchItems[0]?.id);
      this.pack_quantity = drug_id[0]?.pack_quantity;
      this.pocForm.patchValue({ drugs: { drugType: '', drugName: '', otherDrug: '' } }), [this.dT, this.dN] = ['', []];

      // if (this.getRowFormControls.value.filter(f => f.name == this.medName).length > 0) {
      //   this.toastr.info('Drug is already selected');
      //   return;
      // }

      const control = this.pocForm.get('prescribed_medications') as FormArray;
      if(this.enableHumanMMU){
        control.push(this.tableRowForm());
      }else{
        control.push(this.tableRowForm());
      }
      this.selectedsearchItems = [];
      this.searchDrug = '';
      this.srhLst = [];
      this.searchDrugLst = [];
      this.searchLst = [];
    }
    // console.log(this.getRowFormControls.value);
    // this.getRowFormControls.at(0).patchValue({days:25});
  }
  deleteRow(index: number) {
    const control = this.pocForm.get('prescribed_medications') as FormArray;
    control.removeAt(index);
  }

  get getRowFormControls() {
    const control = this.pocForm.get('prescribed_medications') as FormArray;
    return control;
  }

  onRowDDChange(index, value) {
    this.nooftitle = 'No.of ' + value;
    this.placeHolders[index] = 'No.of ' + value;
    this.getRowFormControls.at(index).patchValue({ count: '' });
    if (value != '')
      this.getRowFormControls.at(index).get('count').enable();
    else
      this.getRowFormControls.at(index).get('count').disable();
  }

  tableRowCheckValidations() {
    (this.getRowFormControls.value).forEach((e, i) => {
      if (e.mrng == false && e.aftr == false && e.evng == false && e.night == false && e.sos == false)
        this.getRowFormControls.at(i).get('mrng').setErrors({ 'invalid': true });
    });
    // console.log(this.getRowFormControls.value);
    // console.log(this.getRowFormControls.controls);
  }

  onCheckbox(index) {
    let c = this.getRowFormControls.at(index);
    if (c.get('sos').value == true) {
      // this.isSosChecked = true;
      c.get('mrng').setValue(false);
      c.get('aftr').setValue(false);
      c.get('evng').setValue(false);
      c.get('night').setValue(false);
      c.get('relfood').setValue('Anytime');

    }
    else {
      // this.isSosChecked = false;
      c.get('sos').setValue(false);
      c.get('relfood').setValue('');



    }

    if (this.submitted && (c.get('mrng').value || c.get('aftr').value || c.get('evng').value || c.get('night').value || c.get('sos').value))
      c.get('mrng').setErrors(null);
  }



  openPrescriptionModal() {
    let item = this.pocForm.value;
    item.advice.family_member = this.family_member;
    setTimeout(() => {
      const modalRef = this.modalService.open(PrescriptionComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.prescriptionData = item;
      modalRef.componentInstance.preinstruction = this.pocForm.value.instruction;
      modalRef.componentInstance.provDgns = this.provDgns;
      modalRef.componentInstance.member_mobile = this.depDtls?.member_mobile; // this.benData.member_mobile;
      modalRef.componentInstance.regNo = this.is_self ? this.benId : this.depId;
      modalRef.componentInstance.depDtls = this.depDtls;
      modalRef.componentInstance.show = true;
      modalRef.componentInstance.lab = this.labAddrs;
      modalRef.componentInstance.pharm = this.phrmcyAddrs;
      modalRef.componentInstance.labDisc = $('#labsPerc').val();
      modalRef.componentInstance.phrmDisc = $('#labPhrmPerc').val();
      modalRef.componentInstance.isPcChecked = this.isPcChecked;
      modalRef.componentInstance.refhospId = this.refhospId;
      modalRef.componentInstance.drugsData = this.drugsData;
      modalRef.componentInstance.hospitalName = this.hospitalName;
      modalRef.componentInstance.referral_hospital = this.refhospitalName
      modalRef.componentInstance.procedurevalue = this.procedurevalue;
      modalRef.componentInstance.investigationData = this.previewSelectedInvestigation
      modalRef.result.then(result => {
        if (result == 'SubmitEvent') {
          this.onSubmit(event);
        }
      }, (reason) => { });
    }, 1200);

  }
  //step2
  gotoConsultation() {
    // this.activeModal.close();
    this.withOut_PrescriptionSumitted();
    if (Object.keys(sessionStorage).includes('fromrefpat')) {
      if (sessionStorage.getItem('fromrefpat') == 'referalpatientList') {
        sessionStorage.setItem('fromrefpat', '')
        this.toastr.success('Consultation is Cancelled');
        // if(!this.isOffLinePOC()){

        // }
        if (this.opnd != undefined) {
          this.opnd.close();
        }
        if (this.opndn != undefined) {
          this.opndn.close();
        }
        stopVideo();
        this.router.navigate(['/home/consultation/referalpatientlist']);
      } else {
        this.discoonecttype = 'Patient Disconnected';
        this.toastr.success('Consultation is Cancelled');
        //  if(!this.isOffLinePOC()){

        // }
        if (this.opnd != undefined) {
          this.opnd.close();
        }
        if (this.opndn != undefined) {
          this.opndn.close();
        }
        stopVideo();
        this.router.navigate(['/home/consultation/referalpatientlist']);

      }
    } else {
      this.discoonecttype = 'Patient Disconnected';
      this.toastr.success('Consultation is Cancelled');
      // if(!this.isOffLinePOC()){

      // }
      if (this.opnd != undefined) {
        this.opnd.close();
      }
      if (this.opndn != undefined) {
        this.opndn.close();
      }
      stopVideo();
      this.router.navigate(['/home/consultation/referalpatientlist']);

    }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   alert('By refreshing this page you may lost all data.');
  // }
  //step1 chandu
  withOut_PrescriptionSumitted() {
    let onlyFirstTime: boolean = (<any>window).isPrescriptionSumitted;
    if (!onlyFirstTime) {
      //alert('withOut_PrescriptionSumitted');
      (<any>window).isPrescriptionSumitted = true;
      // const dr = _.find(this.doctordetailsfromstorage, (data) => { return data.id }) chanduuuuu
      let call_type_id: any;
      if (sessionStorage.getItem('call_type') == 'Audio') {
        call_type_id = 9;
      }
      else if (this.isOffLinePOC() == true) {
        call_type_id = 13;
      } else {
        call_type_id = 1;
      }
      let data;
      if (this.isukt) {
        data = {
          "id": 0,
          "patient_interaction_id": this.pocForm.get('patient_interaction_id').value,
          "beneficiary_id": this.benId,
          "doctor_id": this.docData?.id.toString(),
          "call_date": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd'),
          "disconnect_type": this.discoonecttype,
          "memeber_id": this.depId,
          "call_start_time": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd HH:mm:ss'),
          "call_disconnect_time": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          "hub_latitude": this.currLat,
          "hub_longitude": this.currLng,
          "call_type_id ": call_type_id
        }
      } else {
        data = {
          "id": 0,
          "patient_interaction_id": this.pocForm.get('patient_interaction_id').value,
          "beneficiary_id": this.benId,
          "doctor_id": this.docData?.id.toString(),
          "call_date": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd'),
          "disconnect_type": this.discoonecttype,
          "memeber_id": this.depId,
          "call_start_time": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd HH:mm:ss'),
          "call_disconnect_time": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        }
      }

      //chandu 22-09-2022
      //Chandu 20-01-2023 disconnect call 
      // console.log('%%%%%%%%%%',sessionStorage.getItem('myId'));
      let myId = sessionStorage.getItem('myId');
      let callData: any = sessionStorage.getItem('call_status');
      //  console.log('call Data',callData,sessionStorage.getItem('callFromDoc'));
      if (callData.action === 'disconnect' && !callData.isPocGiven) {
        this.registrationService.disconnectCallApi(data).subscribe(resp => {
          this.registrationService.deleteSessionbydocId(this.docData?.id).subscribe(res => {

          });
          // if (resp.status == 'OK') {
          //   this.toastr.success(resp.message);
          // }
        })
      } else if (callData.action === 'disconnect' && callData.isPocGiven) {
        //  console.log('Check 123');
        this.registrationService.deleteSessionbydocId(this.docData?.id).subscribe(res => {

        });
        // if (resp.status == 'OK') {
        //   this.toastr.success(resp.message);
        // }

      }
      // else if(sessionStorage.getItem('callFromDoc')==='1'){

      // }
      else {
        // console.log('Check 345');
        if (myId && myId != "null" && myId != undefined) {

        } else {
          if (!callData.isPocGiven && !this.isOffLinePOC()) {
            this.registrationService.disconnectCallApi(data).subscribe(resp => {
              this.registrationService.deleteSessionbydocId(this.docData?.id).subscribe(res => {

              });
              // if (resp.status == 'OK') {
              //   this.toastr.success(resp.message);
              // }
            })
          } if ((myId == 'null' || myId == undefined || myId == '') && !this.isOffLinePOC()) {
            this.registrationService.disconnectCallApi(data).subscribe(resp => {
              this.registrationService.deleteSessionbydocId(this.docData?.id).subscribe(res => {

              });
              // if (resp.status == 'OK') {
              //   this.toastr.success(resp.message);
              // }
            })
          } else {

          }

        }

      }

    }
  }


  doctorChange(id: string) {
    if (+id > 0) {
      const result = this.doctorList.find(e => e.id == +id);
      // this.pocForm.patchValue({ advice:{referral: result?.doctor_name?.toUpperCase() ?? '' }});  
      this.pocForm.get('advice.referral').patchValue(result.doctor_name?.toUpperCase());
    }
    else
      this.pocForm.get('advice.referral').patchValue('');
  }

  getGender(id: string): string {
    let result = this.gendersList.find(c => c.id == id);
    return (result) ? result.gender_name : '';
  }

  clearData() {
    ['patient_name', 'toId', 'patient_complaints', 'patient_interaction_id', 'order_no', 'hospital_id', 'symptoms', 'is_self', 'family_member']
      .forEach(e => (<HTMLInputElement>document.getElementById(e)).value = '');
  }

  // logOut(isSessionExpired: boolean = false) {
  //   (<HTMLInputElement>document.getElementById('fromId')).value = '';
  //   this.sessionManagement(), this.authServ.logout(), this.modalService.dismissAll(), this.router.navigate(['']);
  //   // this.authenticationService.session(this.userId);             
  //   // this.authenticationService.updateSession(isSessionExpired);
  //   // this.activeModal.close('changes saved');
  //   //Close All WebSockets
  // }

  // sessionManagement() {
  //   if (this.userId) {
  //     this.registrationService.session(this.userId).subscribe(res => {
  //       if (res.status === "success") { }
  //     });
  //   }
  // }

  onTempChng(val: string) {
    this.keyUp('temperature'), this.tempErrMsg = false, sessionStorage.setItem('tempVldtn', '0');
    if (val != '') {
      if (val.includes('.')) {
        let [dotCnt, dcmlCnt] = [val.split('.'), val.split('.')];
        if (dotCnt.length > 0 || dcmlCnt.length > 0) {
          if ((dotCnt.length > 2 || dcmlCnt[1].length > 1) || (dotCnt.length > 0 && dcmlCnt[1].length == 0)) {
            this.tempErrMsg = true, sessionStorage.setItem('tempVldtn', '1');
            return;
          }
        }
      }
    }
  }

  onHmglbnChng(val: string) {
    if (val != '') {
      if (val.includes('.')) {
        let [dotCnt, dcmlCnt] = [val.split('.'), val.split('.')];
        if (dotCnt.length > 0 || dcmlCnt.length > 0) {
          if ((dotCnt.length > 2 || dcmlCnt[1].length > 1) || (dotCnt.length > 0 && dcmlCnt[1].length == 0))
            this.pocForm.get('vital_history.hemoglobin').setValue(''), this.toastr.error('Please enter valid Hemoglobin value');
        }
      }
    }
  }

  //#region WebCam
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  followupclick(item) {
    //this.allergy.patchValue({})
    sessionStorage.setItem('followup', 'followupdata');
    this.resetControls();
    this.consType = "Follow Up";
    this.consultation_no = item.patient_interaction_id;
    if (item.is_epaper) {
      this.toastr.error('Digital prescription can not follow up');
    } else {
      //if (Object.keys(item).includes('prescribed_medications')) { 
      if (this.pocpatientHistory == true) {
        if (this.docData.id == item.doctor_id.id) {
          // if (this.docData.id == item.prescribed_medications.doc_id) {
          this.pocForm.value.findings = item.findings;
          (<FormGroup>this.pocForm.controls['findings']).patchValue(item.findings);
          (<FormGroup>this.pocForm.controls['instructions']).patchValue(item.instructions);
          // new line added for type of consultation 14-04-2023
          // (<FormGroup>this.pocForm.controls['type_of_consultation']).patchValue(item.type_of_consultation);	


          // end Type of COnsultation 

          (<FormGroup>this.pocForm.controls['findings']).patchValue(item.findings);
          (<FormGroup>this.pocForm.controls['allergy']).controls['allergies'].patchValue(item.allergy.allergies);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['temperature'].patchValue(item.vital_history.temperature);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['diabetic_value'].patchValue(item.vital_history.diabetic_value);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['systolic'].patchValue(item.vital_history.systolic);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['diastolic'].patchValue(item.vital_history.diastolic);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['hemoglobin'].patchValue(item.vital_history.hemoglobin);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['rbsk'].patchValue(item.vital_history.rbsk);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['pulse'].patchValue(item.vital_history.pulse);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['oxigen_count'].patchValue(item.vital_history.oxigen_count);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['height'].patchValue(item.vital_history.height);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['weight'].patchValue(item.vital_history.weight);
          (<FormGroup>this.pocForm.controls['vital_history']).controls['bmi'].patchValue(item.vital_history?.bmi);
          (<FormGroup>this.pocForm.controls['other_history']).controls['history'].patchValue(item.other_history.history);
          (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['presentIllness'].patchValue(item.cc_brief_history.presentIllness);
          // Advice 
          (<FormGroup>this.pocForm.controls['advice']).controls['restExercise'].patchValue(item.advice.restExercise);
          (<FormGroup>this.pocForm.controls['advice']).controls['diet'].patchValue(item.advice.diet);
          (<FormGroup>this.pocForm.controls['advice']).controls['followAdvice'].patchValue(item.advice.followAdvice);
          (<FormGroup>this.pocForm.controls['advice']).controls['doctorComment'].patchValue(item.advice.doctorComment);
          //  (<FormGroup>this.pocForm.controls['advice']).controls['followupDate'].patchValue(new Date(item.advice.followupDate));
          (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(item.advice.referral);
          // (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(new Date(item.advice.referral));
          (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['familyhistory'].patchValue(item.cc_brief_history.familyhistory);
          (<FormGroup>this.pocForm.controls['investigation']).controls['otherInvestigation'].patchValue(item.investigation.otherInvestigation);
          if (item.prescribed_medications != undefined && item.prescribed_medications[0]?.instruction) {
            (<FormGroup>this.pocForm.controls['drugs']).controls['instruction'].patchValue(item.prescribed_medications[0].instruction);
          }
          this.ccdata = [];
          var tdArr = [];
          var invgArr = []; var dtArr = []; var dTArrN = []; var dNArr = [];
          //this.ccdata.push(item.cc_brief_history.chiefComplaints[0]);
          for (let i = 0; i < item.cc_brief_history.chiefComplaints.length; i++) {
            this.ccdata.push(item.cc_brief_history.chiefComplaints[i])
          }
          this.ccdata.forEach(e => { this.selectedItems.push(e) })
          this.selectedItemscc = this.ccdata;
          for (let i = 0; i < item.diagnosis.provisionalDiagnosis.length; i++) {
            tdArr.push(item.diagnosis.provisionalDiagnosis[i])
          }
          tdArr.forEach(e => { this.selectedItemstd.push(e) })
          this.selectedItemstd = tdArr;
          for (let i = 0; i < item.investigation.master.length; i++) {
            invgArr.push(item.investigation.master[i])
            console.log("item", item.investigation.master[i]);
          }

          if (invgArr && invgArr.length > 0) {
            invgArr.forEach(e => { this.selectedItemsinvestgations.push(e) })
            this.selectedItemsinvestgations = invgArr;
          }
          if (this.selectedItemsinvestgations.length > 0) {
            setTimeout(() => {
              this.selectedItemsinvestgations.forEach(e => {
                const filterItem: any = this.investigationsList.filter(f => f.id == +e.id);
                this.previewSelectedInvestigation.push(filterItem[0].id);
                this.previewSelectedInvestigation = [...this.previewSelectedInvestigation];

              })
            }, 1000);

          }
          if (item.prescribed_medications != undefined && item.prescribed_medications.length > 0) {
            item.prescribed_medications.forEach((e, i) => {
              e['drug_type'] = e['type'];
              e['drug_name'] = e['name'];
              //this.drugtypesList=this.lstAllDN.master_drug_types
              var dtl = [];
              dtl.push({ drug_type: e.drug_type })
              this.drugtypesList = dtl;
              (<FormGroup>this.pocForm.controls['drugs']).controls['drugType'].patchValue(e.drug_type);
              //this.drug_list = this.drugtypesList.filter(f => f.id == drugid)[0].drug_names;
              var dnl = [];
              dnl.push({ drug_name: e.drug_name })
              this.drug_list = dnl;
              //this.drug_list.push({drug_name:e.drug_name})
              //e['id'] = drugid;
              dtArr.push(e)
              //dtArr.forEach(e => { dTArrN.push(e) })
              this.dT = dtArr;
              this.drugSettings = {
                singleSelection: true,
                idField: 'drug_type',
                textField: 'drug_type',
                selectAllText: 'Select All',
                unSelectAllText: 'Deselect All',
                itemsShowLimit: 1,
                enableCheckAll: false,
                allowSearchFilter: true
              };
              //let drugnameid=this.drug_list.find(a=>a.drug_name==e.name).id;
              (<FormGroup>this.pocForm.controls['drugs']).controls['drugName'].patchValue(e.drug_name);
              // e['idN'] = drugnameid;
              dNArr.push(e)
              this.dN = dNArr;
              this.drugNameSettings = {
                singleSelection: true,
                idField: 'drug_name',
                textField: 'drug_name',
                selectAllText: 'Select All',
                unSelectAllText: 'Deselect All',
                itemsShowLimit: 1,
                enableCheckAll: false,
                allowSearchFilter: true
              };
              if (i >= 0) {
                this.addRow1(e);
                this.getRowFormControls.at(i).patchValue({ mrng: item.prescribed_medications[i].mrng });
                this.getRowFormControls.at(i).patchValue({ aftr: item.prescribed_medications[i].aftr });
                this.getRowFormControls.at(i).patchValue({ evng: item.prescribed_medications[i].evng });
                this.getRowFormControls.at(i).patchValue({ night: item.prescribed_medications[i].night });
                this.getRowFormControls.at(i).patchValue({ sos: item.prescribed_medications[i].sos });
                this.getRowFormControls.at(i).patchValue({ dosage: item.prescribed_medications[i].dosage });
                this.getRowFormControls.at(i).patchValue({ relfood: item.prescribed_medications[i].relfood });
                this.getRowFormControls.at(i).patchValue({ period: item.prescribed_medications[i].period });
                this.getRowFormControls.at(i).patchValue({ count: item.prescribed_medications[i].count });
                this.getRowFormControls.at(i).patchValue({ remarks: item.prescribed_medications[i].remarks });
              }
            })
            this.dT = []; this.dN = [];
            //  this.drugtypesList=this.lstAllDN.master_drug_types
            if (this.lstAllDN.doctor_drug_types.length == 0) {
              this.drugtypesList = this.lstAllDN.master_drug_types
            } else {
              this.drugtypesList = this.lstAllDN.doctor_drug_types
            }
          }

          $("#patientModal").modal("hide");
          if (item.lab_id != null && item.pharmacy_id != null) {

            this.pocForm.patchValue({
              lab_id: item.lab_id.id,
              pharmacy_id: item.pharmacy_id.id
            })
            this.onLabsChng(item.lab_id);
            this.onPhrmcChng(item.pharmacy_id);
          }
        }
        else {
          //this.toastr.info('You are not authorized to view,Please contact admin');
        }
      } else {
        //if(this.docData.id==item.prescribed_medications.doc_id){
        (<FormGroup>this.pocForm.controls['findings']).patchValue(item.findings);
        (<FormGroup>this.pocForm.controls['instructions']).patchValue(item.instructions);
        // new line added for type of consultation 14-04-2023
        //  (<FormGroup>this.pocForm.controls['type_of_consultation']).patchValue(item.type_of_consultation);	
        // end Type of COnsultation 
        (<FormGroup>this.pocForm.controls['allergy']).controls['allergies'].patchValue(item.allergy.allergies);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['temperature'].patchValue(item.vital_history.temperature);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['diabetic_value'].patchValue(item.vital_history.diabetic_value);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['systolic'].patchValue(item.vital_history.systolic);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['diastolic'].patchValue(item.vital_history.diastolic);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['hemoglobin'].patchValue(item.vital_history.hemoglobin);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['rbsk'].patchValue(item.vital_history.rbsk);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['pulse'].patchValue(item.vital_history.pulse);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['oxigen_count'].patchValue(item.vital_history.oxigen_count);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['height'].patchValue(item.vital_history.height);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['weight'].patchValue(item.vital_history.weight);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['bmi'].patchValue(item.vital_history?.bmi);
        (<FormGroup>this.pocForm.controls['other_history']).controls['history'].patchValue(item.other_history.history);
        (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['presentIllness'].patchValue(item.cc_brief_history.presentIllness);
        // Advice
        (<FormGroup>this.pocForm.controls['advice']).controls['restExercise'].patchValue(item.advice.restExercise);
        (<FormGroup>this.pocForm.controls['advice']).controls['diet'].patchValue(item.advice.diet);
        (<FormGroup>this.pocForm.controls['advice']).controls['followAdvice'].patchValue(item.advice.followAdvice);
        (<FormGroup>this.pocForm.controls['advice']).controls['doctorComment'].patchValue(item.advice.doctorComment);
        //  (<FormGroup>this.pocForm.controls['advice']).controls['followupDate'].patchValue(new Date(item.advice.followupDate));
        (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(item.advice.referral);
        // (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(new Date(item.advice.referral));
        (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['familyhistory'].patchValue(item.cc_brief_history.familyhistory);
        //  new line added for master investigations 18-05-2023
        (<FormGroup>this.pocForm.controls['investigation']).controls['master'].patchValue(item.investigation.master);

        // end master investigation 
        (<FormGroup>this.pocForm.controls['investigation']).controls['otherInvestigation'].patchValue(item.investigation.otherInvestigation);
        if (item.prescribed_medications != undefined && item.prescribed_medications[0]?.instruction) {
          (<FormGroup>this.pocForm.controls['drugs']).controls['instruction'].patchValue(item.prescribed_medications[0].instruction);
        }
        this.ccdata = [];
        var tdArr = [];
        var invgArr = []; var dtArr = []; var dTArrN = []; var dNArr = [];
        //this.ccdata.push(item.cc_brief_history.chiefComplaints[0]);
        for (let i = 0; i < item.cc_brief_history.chiefComplaints.length; i++) {
          this.ccdata.push(item.cc_brief_history.chiefComplaints[i])
        }
        this.ccdata.forEach(e => { this.selectedItems.push(e) })
        this.selectedItemscc = this.ccdata;
        for (let i = 0; i < item.diagnosis.provisionalDiagnosis.length; i++) {
          tdArr.push(item.diagnosis.provisionalDiagnosis[i])
        }
        tdArr.forEach(e => { this.selectedItemstd.push(e) })
        this.selectedItemstd = tdArr;
        for (let i = 0; i < item.investigation.master.length; i++) {
          invgArr.push(item.investigation.master[i])
          console.log("item", item.investigation.master[i]);
        }
        if (invgArr && invgArr.length > 0) {
          invgArr.forEach(e => { this.selectedItemsinvestgations.push(e) })
          this.selectedItemsinvestgations = invgArr;
        }
        // new line added for check investigation 18-05-2023

        if (this.selectedItemsinvestgations.length > 0) {
          setTimeout(() => {
            this.selectedItemsinvestgations.forEach(e => {
              const filterItem: any = this.investigationsList.filter(f => f.id == +e.id);
              this.previewSelectedInvestigation.push(filterItem[0].id);
              this.previewSelectedInvestigation = [...this.previewSelectedInvestigation];

            })
          }, 1000);

        }
        if (item.prescribed_medications != undefined && item.prescribed_medications.length > 0) {
          item.prescribed_medications.forEach((e, i) => {
            e['drug_type'] = e['type'];
            e['drug_name'] = e['name'];
            //this.drugtypesList=this.lstAllDN.master_drug_types
            var dtl = [];
            dtl.push({ drug_type: e.drug_type })
            this.drugtypesList = dtl;
            (<FormGroup>this.pocForm.controls['drugs']).controls['drugType'].patchValue(e.drug_type);
            //this.drug_list = this.drugtypesList.filter(f => f.id == drugid)[0].drug_names;
            var dnl = [];
            dnl.push({ drug_name: e.drug_name })
            this.drug_list = dnl;
            //this.drug_list.push({drug_name:e.drug_name})
            //e['id'] = drugid;
            dtArr.push(e)
            //dtArr.forEach(e => { dTArrN.push(e) })
            this.dT = dtArr;
            this.drugSettings = {
              singleSelection: true,
              idField: 'drug_type',
              textField: 'drug_type',
              selectAllText: 'Select All',
              unSelectAllText: 'Deselect All',
              itemsShowLimit: 1,
              enableCheckAll: false,
              allowSearchFilter: true
            };
            //let drugnameid=this.drug_list.find(a=>a.drug_name==e.name).id;
            (<FormGroup>this.pocForm.controls['drugs']).controls['drugName'].patchValue(e.drug_name);
            // e['idN'] = drugnameid;
            dNArr.push(e)
            this.dN = dNArr;
            this.drugNameSettings = {
              singleSelection: true,
              idField: 'drug_name',
              textField: 'drug_name',
              selectAllText: 'Select All',
              unSelectAllText: 'Deselect All',
              itemsShowLimit: 1,
              enableCheckAll: false,
              allowSearchFilter: true
            };
            if (i >= 0) {
              this.addRow1(e)
              this.getRowFormControls.at(i).patchValue({ mrng: item.prescribed_medications[i].mrng });
              this.getRowFormControls.at(i).patchValue({ aftr: item.prescribed_medications[i].aftr });
              this.getRowFormControls.at(i).patchValue({ evng: item.prescribed_medications[i].evng });
              this.getRowFormControls.at(i).patchValue({ night: item.prescribed_medications[i].night });
              this.getRowFormControls.at(i).patchValue({ dosage: item.prescribed_medications[i].dosage });
              this.getRowFormControls.at(i).patchValue({ sos: item.prescribed_medications[i].sos });
              this.getRowFormControls.at(i).patchValue({ relfood: item.prescribed_medications[i].relfood });
              this.getRowFormControls.at(i).patchValue({ period: item.prescribed_medications[i].period });
              this.getRowFormControls.at(i).patchValue({ count: item.prescribed_medications[i].count });
              this.getRowFormControls.at(i).patchValue({ remarks: item.prescribed_medications[i].remarks });
            }
          })
        }

        this.dT = []; this.dN = [];
        //  this.drugtypesList=this.lstAllDN.master_drug_types
        if (this.lstAllDN.doctor_drug_types.length == 0) {
          this.drugtypesList = this.lstAllDN.master_drug_types
        } else {
          this.drugtypesList = this.lstAllDN.doctor_drug_types
        }
        $("#patientModal").modal("hide");
        if (item.lab_id != null && item.pharmacy_id != null) {
          this.pocForm.patchValue({
            lab_id: item.lab_id.id,
            pharmacy_id: item.pharmacy_id.id
          })
        }
        //  }
      }


      // $("#patientModal").modal("hide");
      //  }

    }


  }
  addRow1(item: any) {
    // console.log("item",item);
    // alert(item)
    this.drugs_id = item.id
    this.medName = ''; this.medType = '', [this.medName, this.medType] = [item.drug_name, item.drug_type];
    const control = this.pocForm.get('prescribed_medications') as FormArray;
    if(this.enableHumanMMU){
      control.push(this.tableRowForm());
    }else{
      control.push(this.tableRowForm());
    }
    //this.resetControls();
  }
  resetControls() {
    const arr = this.pocForm.controls.prescribed_medications as FormArray;
    while (0 !== arr.length)
      arr.removeAt(0);
  }
  openpath(item) {
    //if (Object.keys(item).includes('prescribed_medications')) {
    if (this.pocpatientHistory == true) {
      // if (this.docData.id == item.prescribed_medications.doc_id) {
      item.beneficiary_visit_files.forEach((e, i) => {
        //let temp = e.beneficiary_visit_files;
        // let resDgns = e.diagnosis.provisionalDiagnosis;
        if (item.beneficiary_visit_files[i].document_path != null) {
          let temp = item.beneficiary_visit_files.filter(f => f.file_type_id == Avail.VstFleFTId), res = temp.length > 0 ? temp[temp.length - 1] : null, link = document.createElement("a");
          if (res != null)
            this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${res.document_path.split('_')[0]}/${environment.prescription}/${res.document_path}`, link.setAttribute('href', this.imgPath), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
          else
            this.toastr.info('No files found');
        }
        else
          this.toastr.info('No files found');
      })
      //}
      // else {
      //  // this.toastr.info('You are not authorized to view,Please contact admin');
      // }
    } else {
      //if(this.docData.id==item.prescribed_medications.doc_id){
      item.beneficiary_visit_files.forEach((e, i) => {
        //let temp = e.beneficiary_visit_files;
        // let resDgns = e.diagnosis.provisionalDiagnosis;
        if (item.beneficiary_visit_files[i].document_path != null) {
          let temp = item.beneficiary_visit_files.filter(f => f.file_type_id == Avail.VstFleFTId), res = temp.length > 0 ? temp[temp.length - 1] : null, link = document.createElement("a");
          if (res != null)
            this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${res.document_path.split('_')[0]}/${environment.prescription}/${res.document_path}`, link.setAttribute('href', this.imgPath), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
          else
            this.toastr.info('No files found');
        }
        else
          this.toastr.info('No files found');
      })
      //  }
      // else{
      //   this.toastr.info('You are not authorized to view,Please contact admin');
      // }
    }

    //  }
    // else {
    //   this.toastr.info('No files found');
    // }
  }
  followupclick2() {
    this.toastr.info('You are not authorized to view,Please contact admin');
  }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;

        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].feature_id.feature_name == "doctorPocfilter") {
            this.pocpatientHistory = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "isNDHMEnable") {
            this.shwABHDetails = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "isEnableCovid19") {
            this.isEnableCovid19 = res.data[i].patient;
          }
          //Adive Field Enabled
          if (res.data[i].feature_id.feature_name == "isEnableAdviceAllField") {
            this.isEnableAdviceAllField = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "hideOtherDrugLabelinPOC") {
            if (res.data[i].patient) {
              this.hideOtherDrugLabel = true;
            } else {
              this.hideOtherDrugLabel = false;
            }

          }
          if (res.data[i].feature_id.feature_name == "enableConsultationTypeInPOC") {
            this.enableConsultationTypeInPOC = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "enableInsuranceDetailsInPoc") {
            this.enableInsuranceDetailsInPoc = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "enableProvisionalDiagnosis") {
            this.enableProvisionalDiagnosis = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "consultationDate") {
            this.showCreatedOn = res.data[i].patient;
          } if (res.data[i].feature_id.feature_name == "disableSearchByDrugName") {
            this.disableSearchByDrugName = res.data[i].patient;
          } if (res.data[i].feature_id.feature_name == "HimsReferral") {
            this.HimsReferral = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "EnableHIMSFileinPOC") {
            this.EnableHIMSFileinPOC = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "EnableOptimetryDetailsInPOC") {
            this.EnableOptimetryDetailsInPOC = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name  == "EnableHumanMMU") {        
            this.enableHumanMMU = res.data[i].patient;
          }

        }
      }
    });
  }
  openPatientModal() {
    //this.router.navigate(['home/activedoctorslist'])
    this.openvideotag = true;
    const modalRef = this.modalService.open(ActiveDoctorsListComponent, { scrollable: true, size: 'xl' });
    // modalRef.componentInstance.title = this.modalTitle;
  }
  showHideVideo2() {
    // const fromPatient = JSON.parse(sessionStorage.isPatient || 'false');
    if (this.openvideotag == true) {
      if (this.isOffLinePOC() == true) {
        let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
        dragElement.style.display = (dragElement.style.display == 'none') ? 'block' : 'block';
        sessionStorage.isPatient = false;
      }
    }
  }
  // statusreferdata(){
  //   this.registrationService.statusrefer().subscribe(res => {
  // this.statusget=res.data;
  //   })
  // }
  onRefHospSubmit() {
    if (this.isOffLinePOC() == true) {
      if (sessionStorage.getItem('fromrefpat') == 'referalpatientList') {
        if ((<HTMLInputElement>document.getElementById('procedurevalue')).value) {
          this.procedurevalue = (<HTMLInputElement>document.getElementById('procedurevalue')).value;
        }
        if ((<HTMLInputElement>document.getElementById('statusvalue')).value) {
          this.statusdata = (<HTMLInputElement>document.getElementById('statusvalue')).value;
        }

        if ((<HTMLInputElement>document.getElementById('refdoctorName')).value) {
          this.refdoctorName = (<HTMLInputElement>document.getElementById('refdoctorName')).value;
        }
      }
    }
    //chandu 31-05-2022
    if (this.statusdata && this.statusdata != null && this.statusdata !== ' ' && this.refdoctorName && this.refdoctorName != null && this.refdoctorName !== ' ') {
      let payLoad;
      if (this.isOffLinePOC()) {
        if (!this.is_self) {
          payLoad = {
            "beneficiary_id": '',
            "dependent_id": this.benId,
            "doctor_id": this.docData.id,
            "referral_hospital_id": this.refhospId,
            "is_active": true,
            "beneficiary_name": this.benData?.first_name + '' + this.benData.middle_name + '' + this.benData.last_name,
            "beneficiary_mobile": this.benData?.mobile,
            "schedule_date": null,
            "before_ref_poc_id": this.beforepocid,
            "after_ref_poc_id": null,
            "procedure": this.procedurevalue,
            // "referral_type_id": this.statusdata,
            "referral_type_id": 1,
            "doctor_name": this.refdoctorName
          }
        } else {
          payLoad = {
            "beneficiary_id": this.benId,
            "dependent_id": null,
            "doctor_id": this.docData.id,
            "referral_hospital_id": this.refhospId,
            "is_active": true,
            "beneficiary_name": this.benData?.first_name + '' + this.benData.middle_name + '' + this.benData.last_name,
            "beneficiary_mobile": this.benData?.mobile,
            "schedule_date": null,
            "before_ref_poc_id": this.beforepocid,
            "after_ref_poc_id": null,
            "procedure": this.procedurevalue,
            // "referral_type_id": this.statusdata,
            "referral_type_id": 1,
            "doctor_name": this.refdoctorName
          }
        }
      } else {
        if (!this.is_self) {
          payLoad = {
            "beneficiary_id": '',
            "dependent_id": sessionStorage.getItem('callingpatient_id_fm'),
            "doctor_id": this.docData.id,
            "referral_hospital_id": this.refhospId,
            "is_active": true,
            "beneficiary_name": this.benData?.first_name + '' + this.benData.middle_name + '' + this.benData.last_name,
            "beneficiary_mobile": this.benData?.mobile,
            "schedule_date": null,
            "before_ref_poc_id": this.beforepocid,
            "after_ref_poc_id": null,
            "procedure": this.procedurevalue,
            // "referral_type_id": this.statusdata,
            "referral_type_id": 1,
            "doctor_name": this.refdoctorName
          }
        } else {
          payLoad = {
            "beneficiary_id": this.benId,
            "dependent_id": null,
            "doctor_id": this.docData.id,
            "referral_hospital_id": this.refhospId,
            "is_active": true,
            "beneficiary_name": this.benData?.first_name + ' ' + this.benData.middle_name + ' ' + this.benData.last_name,
            "beneficiary_mobile": this.benData?.mobile,
            "schedule_date": null,
            "before_ref_poc_id": this.beforepocid,
            "after_ref_poc_id": null,
            "procedure": this.procedurevalue,
            // "referral_type_id": this.statusdata,
            "referral_type_id": 1,
            "doctor_name": this.refdoctorName
          }
        }
      }

      if (sessionStorage.getItem('fromrefpat') == 'referalpatientList') {
        payLoad.after_ref_poc_id = this.beforepocid;
        if (sessionStorage.getItem('schedule_date') == 'null' || sessionStorage.getItem('schedule_date') == undefined || sessionStorage.getItem('schedule_date') == '') {
          payLoad.schedule_date = null;
        } else {
          payLoad.schedule_date = sessionStorage.getItem('schedule_date');
        }

        payLoad.before_ref_poc_id = sessionStorage.getItem('beforepocid');
        payLoad['id'] = sessionStorage.getItem('afterrefid');
        payLoad.doctor_id = sessionStorage.getItem('ref_doc_id');
        payLoad.doctor_name = sessionStorage.getItem('ref_doc_name');
        payLoad.referral_type_id = this.refStatus;
        payLoad.referral_hospital_id = sessionStorage.getItem('ref_hosp_id_ref');
      }
      if (this.memberType == 'false') {
        payLoad.dependent_id = this.depId
      }
      this.registrationService.savereferal(payLoad).subscribe(res => {
        if (res.status == 'OK') {
          //  sessionStorage.
          this.toastr.info(res.message);
          this.router.navigate(['/home/consultation/cn'])
        } else {
          this.toastr.error(res.err)
        }
      })
    }

  }
  getAgentdrugs() {
    // let dt = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    // let payLoad = {
    //   "agentId": this.benAgentID,
    //   "receviedDate": dt
    // }
    this.registrationService.AgentDruglist(this.benAgentID).subscribe(res => {
      if (res.status == 'OK') {
        this.drugsData = res.data;
      } else {
        //this.toastr.error(res.err);
      }
    })
  }
  docSkipCallclick() {
    this.toastr.error('Doctor disconnected call');
    const covid19 = document.getElementById('btnCovid19Close');
    if (covid19)
      covid19.click();
  }
  navigatepocclick() {
    setTimeout(() => {
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
      dragElement.style.display = 'none';
      this.toastr.error('Call is disconnected');
      stopVideo();
      this.opnd.close();
      this.opndn.close();
      this.clearData();
      this.router.navigate(['/home/consultation/cn'])
      this.opnd.close();
      this.opndn.close();
    }, 2000);
    // document.getElementById('prescriptionModalClose').click(), this.submitted = false, stopVideo(),
    //    document.getElementById('prescriptionModalClose').click(), this.changeMenu(), this.submitted = false, stopVideo(),
    //    this.initFormControls(),
    //    this.clearData(), 
    //    setTimeout(() => {
    //     this.router.navigate(['home/consultation'])
    // },2000);
  }

  navigatepocIsPocGivenTrueclick() {
    setTimeout(() => {
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
      dragElement.style.display = 'none';
      isPocGivenstopVideo(); this.clearData();
      this.router.navigate(['/home/consultation/cn'])
      this.opnd.close();
      this.opndn.close();
    }, 2000);
    // document.getElementById('prescriptionModalClose').click(), this.submitted = false, stopVideo(),
    //    document.getElementById('prescriptionModalClose').click(), this.changeMenu(), this.submitted = false, stopVideo(),
    //    this.initFormControls(),
    //    this.clearData(), 
    //    setTimeout(() => {
    //     this.router.navigate(['home/consultation'])
    // },2000);
  }
  followupclickref(item) {

    this.resetControls();
    this.consType = "Follow Up";
    if (Object.keys(item).includes('prescribed_medications')) {
      if (this.pocpatientHistory == true) {
        (<FormGroup>this.pocForm.controls['findings']).patchValue(item.findings);
        (<FormGroup>this.pocForm.controls['instructions']).patchValue(item.instructions);
        // new line added for type of consultation 14-04-2023
        //  (<FormGroup>this.pocForm.controls['type_of_consultation']).patchValue(item.type_of_consultation);	
        // end Type of COnsultation 
        (<FormGroup>this.pocForm.controls['allergy']).controls['allergies'].patchValue(item.allergy.allergies);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['temperature'].patchValue(item.vital_history.temperature);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['diabetic_value'].patchValue(item.vital_history.diabetic_value);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['systolic'].patchValue(item.vital_history.systolic);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['diastolic'].patchValue(item.vital_history.diastolic);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['hemoglobin'].patchValue(item.vital_history.hemoglobin);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['rbsk'].patchValue(item.vital_history.rbsk);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['pulse'].patchValue(item.vital_history.pulse);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['oxigen_count'].patchValue(item.vital_history.oxigen_count);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['height'].patchValue(item.vital_history.height);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['weight'].patchValue(item.vital_history.weight);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['bmi'].patchValue(item.vital_history?.bmi);
        (<FormGroup>this.pocForm.controls['other_history']).controls['history'].patchValue(item.other_history.history);
        (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['presentIllness'].patchValue(item.cc_brief_history.presentIllness);
        // Advice
        (<FormGroup>this.pocForm.controls['advice']).controls['restExercise'].patchValue(item.advice.restExercise);
        (<FormGroup>this.pocForm.controls['advice']).controls['diet'].patchValue(item.advice.diet);
        (<FormGroup>this.pocForm.controls['advice']).controls['followAdvice'].patchValue(item.advice.followAdvice);
        (<FormGroup>this.pocForm.controls['advice']).controls['doctorComment'].patchValue(item.advice.doctorComment);
        //  (<FormGroup>this.pocForm.controls['advice']).controls['followupDate'].patchValue(new Date(item.advice.followupDate));
        (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(item.advice.referral);
        // (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(new Date(item.advice.referral));
        (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['familyhistory'].patchValue(item.cc_brief_history.familyhistory);
        //  new line added for master investigations 18-05-2023
        (<FormGroup>this.pocForm.controls['investigation']).controls['master'].patchValue(item.investigation.master);

        // end master investigation 
        (<FormGroup>this.pocForm.controls['investigation']).controls['otherInvestigation'].patchValue(item.investigation.otherInvestigation);
        (<FormGroup>this.pocForm.controls['drugs']).controls['instruction'].patchValue(item.prescribed_medications[0].instruction);
        this.ccdata = [];
        var tdArr = [];
        var invgArr = []; var dtArr = []; var dTArrN = []; var dNArr = [];
        //this.ccdata.push(item.cc_brief_history.chiefComplaints[0]);
        for (let i = 0; i < item.cc_brief_history.chiefComplaints.length; i++) {
          this.ccdata.push(item.cc_brief_history.chiefComplaints[i])
        }
        this.ccdata.forEach(e => { this.selectedItems.push(e) })
        this.selectedItemscc = this.ccdata;
        for (let i = 0; i < item.diagnosis.provisionalDiagnosis.length; i++) {
          tdArr.push(item.diagnosis.provisionalDiagnosis[i])
        }
        tdArr.forEach(e => { this.selectedItemstd.push(e) })
        this.selectedItemstd = tdArr;
        for (let i = 0; i < item.investigation.master.length; i++) {
          invgArr.push(item.investigation.master[i])
          console.log("item", item.investigation.master[i]);
        }
        invgArr.forEach(e => { this.selectedItemsinvestgations.push(e) })
        this.selectedItemsinvestgations = invgArr;

        item.prescribed_medications.forEach((e, i) => {
          e['drug_type'] = e['type'];
          e['drug_name'] = e['name'];
          //this.drugtypesList=this.lstAllDN.master_drug_types
          var dtl = [];
          dtl.push({ drug_type: e.drug_type })
          this.drugtypesList = dtl;
          (<FormGroup>this.pocForm.controls['drugs']).controls['drugType'].patchValue(e.drug_type);
          //this.drug_list = this.drugtypesList.filter(f => f.id == drugid)[0].drug_names;
          var dnl = [];
          dnl.push({ drug_name: e.drug_name })
          this.drug_list = dnl;
          //this.drug_list.push({drug_name:e.drug_name})
          //e['id'] = drugid;
          dtArr.push(e)
          //dtArr.forEach(e => { dTArrN.push(e) })
          this.dT = dtArr;
          this.drugSettings = {
            singleSelection: true,
            idField: 'drug_type',
            textField: 'drug_type',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
          };
          //let drugnameid=this.drug_list.find(a=>a.drug_name==e.name).id;
          (<FormGroup>this.pocForm.controls['drugs']).controls['drugName'].patchValue(e.drug_name);
          // e['idN'] = drugnameid;
          dNArr.push(e)
          this.dN = dNArr;
          this.drugNameSettings = {
            singleSelection: true,
            idField: 'drug_name',
            textField: 'drug_name',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
          };
          if (i >= 0) {
            this.addRow1(e)
            this.getRowFormControls.at(i).patchValue({ mrng: item.prescribed_medications[i].mrng });
            this.getRowFormControls.at(i).patchValue({ aftr: item.prescribed_medications[i].aftr });
            this.getRowFormControls.at(i).patchValue({ evng: item.prescribed_medications[i].evng });
            this.getRowFormControls.at(i).patchValue({ night: item.prescribed_medications[i].night });
            this.getRowFormControls.at(i).patchValue({ sos: item.prescribed_medications[i].sos });
            this.getRowFormControls.at(i).patchValue({ dosage: item.prescribed_medications[i].dosage });
            this.getRowFormControls.at(i).patchValue({ relfood: item.prescribed_medications[i].relfood });
            this.getRowFormControls.at(i).patchValue({ period: item.prescribed_medications[i].period });
            this.getRowFormControls.at(i).patchValue({ count: item.prescribed_medications[i].count });
            this.getRowFormControls.at(i).patchValue({ remarks: item.prescribed_medications[i].remarks });
          }
        })
        this.dT = []; this.dN = [];
        //  this.drugtypesList=this.lstAllDN.master_drug_types
        if (this.lstAllDN.doctor_drug_types.length == 0) {
          this.drugtypesList = this.lstAllDN.master_drug_types
        } else {
          this.drugtypesList = this.lstAllDN.doctor_drug_types
        }
        $("#patientModal").modal("hide");
        if (item.lab_id != null && item.pharmacy_id != null) {
          this.pocForm.patchValue({
            lab_id: item.lab_id.id,
            pharmacy_id: item.pharmacy_id.id
          })
        }
      } else {
        //if(this.docData.id==item.prescribed_medications.doc_id){
        (<FormGroup>this.pocForm.controls['findings']).patchValue(item.findings);
        (<FormGroup>this.pocForm.controls['instructions']).patchValue(item.instructions);
        // new line added for type of consultation 14-04-2023
        // (<FormGroup>this.pocForm.controls['type_of_consultation']).patchValue(item.type_of_consultation);	
        // end Type of COnsultation 
        (<FormGroup>this.pocForm.controls['allergy']).controls['allergies'].patchValue(item.allergy.allergies);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['temperature'].patchValue(item.vital_history.temperature);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['diabetic_value'].patchValue(item.vital_history.diabetic_value);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['systolic'].patchValue(item.vital_history.systolic);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['diastolic'].patchValue(item.vital_history.diastolic);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['hemoglobin'].patchValue(item.vital_history.hemoglobin);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['rbsk'].patchValue(item.vital_history.rbsk);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['pulse'].patchValue(item.vital_history.pulse);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['oxigen_count'].patchValue(item.vital_history.oxigen_count);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['height'].patchValue(item.vital_history.height);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['weight'].patchValue(item.vital_history.weight);
        (<FormGroup>this.pocForm.controls['vital_history']).controls['bmi'].patchValue(item.vital_history?.bmi);
        (<FormGroup>this.pocForm.controls['other_history']).controls['history'].patchValue(item.other_history.history);
        (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['presentIllness'].patchValue(item.cc_brief_history.presentIllness);
        // Advice
        (<FormGroup>this.pocForm.controls['advice']).controls['restExercise'].patchValue(item.advice.restExercise);
        (<FormGroup>this.pocForm.controls['advice']).controls['diet'].patchValue(item.advice.diet);
        (<FormGroup>this.pocForm.controls['advice']).controls['followAdvice'].patchValue(item.advice.followAdvice);
        (<FormGroup>this.pocForm.controls['advice']).controls['doctorComment'].patchValue(item.advice.doctorComment);
        //  (<FormGroup>this.pocForm.controls['advice']).controls['followupDate'].patchValue(new Date(item.advice.followupDate));
        (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(item.advice.referral);
        // (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(new Date(item.advice.referral));
        (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['familyhistory'].patchValue(item.cc_brief_history.familyhistory);
        //  new line added for master investigations 18-05-2023
        (<FormGroup>this.pocForm.controls['investigation']).controls['master'].patchValue(item.investigation.master);

        // end master investigation 
        (<FormGroup>this.pocForm.controls['investigation']).controls['otherInvestigation'].patchValue(item.investigation.otherInvestigation);
        (<FormGroup>this.pocForm.controls['drugs']).controls['instruction'].patchValue(item.prescribed_medications[0]?.instruction);
        this.ccdata = [];
        var tdArr = [];
        var invgArr = []; var dtArr = []; var dTArrN = []; var dNArr = [];
        //this.ccdata.push(item.cc_brief_history.chiefComplaints[0]);
        for (let i = 0; i < item.cc_brief_history.chiefComplaints.length; i++) {
          this.ccdata.push(item.cc_brief_history.chiefComplaints[i])
        }
        this.ccdata.forEach(e => { this.selectedItems.push(e) })
        this.selectedItemscc = this.ccdata;
        for (let i = 0; i < item.diagnosis.provisionalDiagnosis.length; i++) {
          tdArr.push(item.diagnosis.provisionalDiagnosis[i])
        }
        tdArr.forEach(e => { this.selectedItemstd.push(e) })
        this.selectedItemstd = tdArr;
        for (let i = 0; i < item.investigation.master.length; i++) {
          invgArr.push(item.investigation.master[i])
          console.log("item", item.investigation.master[i]);
        }
        invgArr.forEach(e => { this.selectedItemsinvestgations.push(e) })
        this.selectedItemsinvestgations = invgArr;

        item.prescribed_medications.forEach((e, i) => {
          e['drug_type'] = e['type'];
          e['drug_name'] = e['name'];
          //this.drugtypesList=this.lstAllDN.master_drug_types
          var dtl = [];
          dtl.push({ drug_type: e.drug_type })
          this.drugtypesList = dtl;
          (<FormGroup>this.pocForm.controls['drugs']).controls['drugType'].patchValue(e.drug_type);
          //this.drug_list = this.drugtypesList.filter(f => f.id == drugid)[0].drug_names;
          var dnl = [];
          dnl.push({ drug_name: e.drug_name })
          this.drug_list = dnl;
          //this.drug_list.push({drug_name:e.drug_name})
          //e['id'] = drugid;
          dtArr.push(e)
          //dtArr.forEach(e => { dTArrN.push(e) })
          this.dT = dtArr;
          this.drugSettings = {
            singleSelection: true,
            idField: 'drug_type',
            textField: 'drug_type',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
          };
          //let drugnameid=this.drug_list.find(a=>a.drug_name==e.name).id;
          (<FormGroup>this.pocForm.controls['drugs']).controls['drugName'].patchValue(e.drug_name);
          // e['idN'] = drugnameid;
          dNArr.push(e)
          this.dN = dNArr;
          this.drugNameSettings = {
            singleSelection: true,
            idField: 'drug_name',
            textField: 'drug_name',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
          };
          if (i >= 0) {
            this.addRow1(e)
            this.getRowFormControls.at(i).patchValue({ mrng: item.prescribed_medications[i].mrng });
            this.getRowFormControls.at(i).patchValue({ aftr: item.prescribed_medications[i].aftr });
            this.getRowFormControls.at(i).patchValue({ evng: item.prescribed_medications[i].evng });
            this.getRowFormControls.at(i).patchValue({ night: item.prescribed_medications[i].night });
            this.getRowFormControls.at(i).patchValue({ night: item.prescribed_medications[i].sos });
            this.getRowFormControls.at(i).patchValue({ sos: item.prescribed_medications[i].sos });
            this.getRowFormControls.at(i).patchValue({ dosage: item.prescribed_medications[i].dosage });
            this.getRowFormControls.at(i).patchValue({ relfood: item.prescribed_medications[i].relfood });
            this.getRowFormControls.at(i).patchValue({ period: item.prescribed_medications[i].period });
            this.getRowFormControls.at(i).patchValue({ count: item.prescribed_medications[i].count });
            this.getRowFormControls.at(i).patchValue({ remarks: item.prescribed_medications[i].remarks });
          }
        })
        this.dT = []; this.dN = [];
        //  this.drugtypesList=this.lstAllDN.master_drug_types
        if (this.lstAllDN.doctor_drug_types.length == 0) {
          this.drugtypesList = this.lstAllDN.master_drug_types
        } else {
          this.drugtypesList = this.lstAllDN.doctor_drug_types
        }
        $("#patientModal").modal("hide");
        if (item.lab_id != null && item.pharmacy_id != null) {
          this.pocForm.patchValue({
            lab_id: item.lab_id.id,
            pharmacy_id: item.pharmacy_id.id
          })
        }
        //  }
      }


      // $("#patientModal").modal("hide");
    }
    else {
      //if(this.docData.id==item.prescribed_medications.doc_id){
      this.pocForm.value.findings = item.findings;
      (<FormGroup>this.pocForm.controls['allergy']).controls['allergies'].patchValue(item.allergy.allergies);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['temperature'].patchValue(item.vital_history.temperature);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['diabetic_value'].patchValue(item.vital_history.diabetic_value);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['systolic'].patchValue(item.vital_history.systolic);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['diastolic'].patchValue(item.vital_history.diastolic);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['hemoglobin'].patchValue(item.vital_history.hemoglobin);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['rbsk'].patchValue(item.vital_history.rbsk);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['pulse'].patchValue(item.vital_history.pulse);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['oxigen_count'].patchValue(item.vital_history.oxigen_count);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['height'].patchValue(item.vital_history.height);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['weight'].patchValue(item.vital_history.weight);
      (<FormGroup>this.pocForm.controls['vital_history']).controls['bmi'].patchValue(item.vital_history?.bmi);
      (<FormGroup>this.pocForm.controls['other_history']).controls['history'].patchValue(item.other_history.history);
      (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['presentIllness'].patchValue(item.cc_brief_history.presentIllness);
      // Advice
      (<FormGroup>this.pocForm.controls['advice']).controls['restExercise'].patchValue(item.advice.restExercise);
      (<FormGroup>this.pocForm.controls['advice']).controls['diet'].patchValue(item.advice.diet);
      (<FormGroup>this.pocForm.controls['advice']).controls['followAdvice'].patchValue(item.advice.followAdvice);
      (<FormGroup>this.pocForm.controls['advice']).controls['doctorComment'].patchValue(item.advice.doctorComment);
      //  (<FormGroup>this.pocForm.controls['advice']).controls['followupDate'].patchValue(new Date(item.advice.followupDate));
      (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(item.advice.referral);
      // (<FormGroup>this.pocForm.controls['advice']).controls['referral'].patchValue(new Date(item.advice.referral));
      (<FormGroup>this.pocForm.controls['cc_brief_history']).controls['familyhistory'].patchValue(item.cc_brief_history.familyhistory);
      //  new line added for master investigations 18-05-2023
      (<FormGroup>this.pocForm.controls['investigation']).controls['master'].patchValue(item.investigation.master);

      // end master investigation 
      (<FormGroup>this.pocForm.controls['investigation']).controls['otherInvestigation'].patchValue(item.investigation.otherInvestigation);
      (<FormGroup>this.pocForm.controls['drugs']).controls['instruction'].patchValue(item.prescribed_medications[0].instruction);
      this.ccdata = [];
      var tdArr = [];
      var invgArr = []; var dtArr = []; var dTArrN = []; var dNArr = [];
      //this.ccdata.push(item.cc_brief_history.chiefComplaints[0]);
      for (let i = 0; i < item.cc_brief_history.chiefComplaints.length; i++) {
        this.ccdata.push(item.cc_brief_history.chiefComplaints[i])
      }
      this.ccdata.forEach(e => { this.selectedItems.push(e) })
      this.selectedItemscc = this.ccdata;
      for (let i = 0; i < item.diagnosis.provisionalDiagnosis.length; i++) {
        tdArr.push(item.diagnosis.provisionalDiagnosis[i])
      }
      tdArr.forEach(e => { this.selectedItemstd.push(e) })
      this.selectedItemstd = tdArr;
      for (let i = 0; i < item.investigation.master.length; i++) {
        invgArr.push(item.investigation.master[i])
        console.log("item", item.investigation.master[i]);

      }
      invgArr.forEach(e => { this.selectedItemsinvestgations.push(e) })
      this.selectedItemsinvestgations = invgArr;
      this.dT = []; this.dN = [];
      //  this.drugtypesList=this.lstAllDN.master_drug_types
      if (this.lstAllDN.doctor_drug_types.length == 0) {
        this.drugtypesList = this.lstAllDN.master_drug_types
      } else {
        this.drugtypesList = this.lstAllDN.doctor_drug_types
      }
      $("#patientModal").modal("hide");
      if (item.lab_id != null && item.pharmacy_id != null) {
        this.pocForm.patchValue({
          lab_id: item.lab_id.id,
          pharmacy_id: item.pharmacy_id.id
        })
      }
      //  }
    }
  }
  viewAgentStock() {
    //this.formStockData = this.pocForm.value;
    for (let i = 0; i < this.pocForm.value.prescribed_medications.length; i++) {
      this.pocForm.value.prescribed_medications[i].name = this.pocForm.value.prescribed_medications[i].name.toUpperCase();
      //  this.prescriptionData.prescribed_medications[i]['cost'] = '';

      for (let j = 0; j < this.drugsData.length; j++) {
        if (this.drugsData[j].drug_name != undefined) {
          this.drugsData[j].drug_name = this.drugsData[j].drug_name.toUpperCase();
        }
        if (this.pocForm.value.prescribed_medications[i].name == this.drugsData[j].drug_name) {
          this.pocForm.value.prescribed_medications[i].agentStock = this.drugsData[j].stock;
          let newDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
          let expDate = this.datePipe.transform(this.drugsData[j].expiry_date, "yyyy-MM-dd");
          if (expDate <= newDate) {
            this.getRowFormControls.at(i).patchValue({ agentStock: 0 });
          } else {
            this.getRowFormControls.at(i).patchValue({ agentStock: this.drugsData[j].stock });
          }

          // if(this.formStockData.prescribed_medications[i].agentStock == ""){
          //   this.noteData = this.prescriptionData.prescribed_medications[i].name +'is not available with the phc pharmacy and need to be purchase from outside pharmacy'
          // }


        }
      }
    }
    // sessionStorage.setItem('agentstock',JSON.stringify(this.formStockData.prescribed_medications))
  }
  onProcedure() {
    this.procedurevalue = (<HTMLInputElement>document.getElementById('procedurevalue')).value;
  }
  //#endregion

  // clickForMore() {
  //   this.textMore = (this.textMore == " ShowLess") ? " MoreOptions..." : " ShowLess";
  //   if (this.textMore === " ShowLess")
  //     this.chiefComplaintList = this.totalData;
  //   else
  //     this.chiefComplaintList = this.totalData.filter(n => this.totalData.indexOf(n) < 5), this.textMore = " MoreOptions...";
  // }

  // sushma 22-07-2022 type of diagnosis values based on search
  typeDiagnosis() {
    // document.getElementById('openOnEvent').click();
    if (this.searchDrugs) {
      let drId = JSON.parse(sessionStorage.getItem('DoctorDetails'))?.id ?? null;
      if (this.typeOfdiagnosis.length > 2) {
        this.diagnosysdropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'diagnosis_name',
          selectAllText: 'Select All',
          unSelectAllText: 'Deselect All',
          itemsShowLimit: 10,
          enableCheckAll: false,
          allowSearchFilter: false,
          defaultOpen: true
        }
        this.registrationService.postPDlist(`${drId}&diagnosisName=${this.typeOfdiagnosis}`).subscribe(res => {

          this.typeofDiagnosisLst = res.data?.master_provisional_diagnosis;
          if (this.checkedLst.length > 0) {
            this.checkedLst.forEach(e => {
              let obj = this.typeofDiagnosisLst.find(a => a.id == e.id);
              if (obj == null)
                this.typeofDiagnosisLst.push(e);
            })
            this.typeofDiagnosisLst = this.typeofDiagnosisLst.filter(
              (element, i) => i === this.typeofDiagnosisLst.indexOf(element)
            );
            // const expected = new Set();
            // this.typeofDiagnosisLst=this.typeofDiagnosisLst.filter(item => !expected.has(JSON.stringify(item)) ? expected.add(JSON.stringify(item)) : false);
            this.typeofDiagnosisLst = [...this.typeofDiagnosisLst];
          }
        })
      }
      this.setProvisionalValues();
    }
  }

  // new line added for search drug name 07-07-2023 #chandu
  searchDrugName() {
    // document.getElementById('openOnEvent').click();
    if (this.searchDrugs) {
      let drId = JSON.parse(sessionStorage.getItem('DoctorDetails'))?.id ?? null;
      if (this.searchByDrugName.length > 2) {
        this.drugNameSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'drug_name',
          selectAllText: 'Select All',
          unSelectAllText: 'Deselect All',
          closeDropDownOnSelection: true,
          itemsShowLimit: 1,
          enableCheckAll: false,
          allowSearchFilter: true
        };

        this.LoadDrugsbySearchDTId(this.drugId);
        // this.registrationService.getDrugsListBySearch().subscribe(res => {

        //   this.searchByDrugNameList = res.data?.master_provisional_diagnosis;
        //   if (this.checkedLst.length > 0) {
        //     this.checkedLst.forEach(e => {
        //       let obj = this.typeofDiagnosisLst.find(a => a.id == e.id);
        //       if (obj == null)
        //         this.typeofDiagnosisLst.push(e);
        //     })
        //     this.typeofDiagnosisLst = this.typeofDiagnosisLst.filter(
        //       (element, i) => i === this.typeofDiagnosisLst.indexOf(element)
        //     );
        //     // const expected = new Set();
        //     // this.typeofDiagnosisLst=this.typeofDiagnosisLst.filter(item => !expected.has(JSON.stringify(item)) ? expected.add(JSON.stringify(item)) : false);
        //     this.typeofDiagnosisLst = [...this.typeofDiagnosisLst];
        //   }
        // })
      }
      // this.setProvisionalValues();
    } else {
      if (this.searchByDrugName.length < 3) {

      }
    }
  }
  // end line search drug name
  onSelectdrug(item: any, type: string) {
    if (type == 's') {
      this.searchCheckedLst = item;
    }
    else if (type == 'd') {
      this.selectedsearchItems.filter(item => item !== item);
      this.searchCheckedLst = this.selectedsearchItems;
    }

  }

  onDrugSelect() {
    let drugtype = 'medicine';
    this.tenantId = sessionStorage.getItem('currentTenant');
    if (this.searchDrug?.length > 2) {
      this.searchdrugdropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'drug_name',
        selectAllText: 'Select All',
        unSelectAllText: 'Deselect All',
        closeDropDownOnSelection: true,
        itemsShowLimit: 10,
        enableCheckAll: false,
        allowSearchFilter: true,
        defaultOpen: true
      };

      this.registrationService.getDrugName(`poc-emr/jwt/doctor/poc/searchmedicine?name=${this.searchDrug}&type=${drugtype}&category=allopathy&TENANT_ID=${this.tenantId}`).subscribe(res => {
        if (res) {
          this.searchDrugLst = res.data;
          this.searchDrugLst.forEach(f => {
            this.searchLst.push({
              id: f.drug_id?.id,
              drug_name: f.drug_id?.drug_name,
              pack_quantity: f.drug_id?.pack_quantity,
              type: f.drug_type_id.drug_type
            })
          })
          this.srhLst = this.searchLst;
          // console.log(this.srhLst);
        }
      }, err => { this.toastr.error("No data found") }

      )

    }


  }

  getFeatersdetails() {
    this.tenantId = sessionStorage.getItem('currentTenant');
    this.registrationService.getFeatures(`get-features/${this.tenantId}`).subscribe(res => {
      if (res)
        this.featureDetails = res.data;
      let dta = this.featureDetails.filter(f => f.feature_id?.feature_name == 'isMedPay')
      dta[0]?.patient == true ? this.isFeature = true : this.isFeature = false;
    })
  }
  // token() {


  //   const payload1 = {
  //     id:this.benId,
  //     is_vital_captured:true,
  //     is_poc_completed:false 
  //   }
  //   if(this.is_self){
  //     payload1.id=this.benId
  //   } else{
  //     payload1.id=this.memberData.beneficiary_id
  //   }
  //   this.registrationService.save(`updatepatentinteraction`, payload1).subscribe(res => {
  //     if (res?.status === "OK") {
  //       if (res?.data == null || res?.data.length == 0) {
  //         this.toastr.info('No records available');
  //         this.tokenData = [];
  //       }
  //       else
  //         this.tokenData = res.data;     
  //     }
  //   },
  //     error => { this.toastr.error(error.message); }
  //   );
  // }
  ReferralHospital() {
    // if(this.HimsReferral){
      this.registrationService.getAllData(`poc-emr/hims/getallhimscenters`).subscribe(res => {
        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0)
            this.toastr.info(res.message);
          else
            this.referralHospTypeList = res.data?.content;
        }
      });
    // }
    
  }
  onReferral(value: any) {
    if (value.length != 0) {
      this.isHimsreferral = true
    }
  }
  openEmr() {
    // this.router.navigate(['/home/sharedemr/emr'])
    const modalRef = this.modalService.open(PatientEmrComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = 'EMR / Patient EMR';

  }

  bmiCheck() {

    let hgt = (this.pocForm.value.vital_history.height / 100).toFixed(2);
    if (this.pocForm.value.vital_history.weight && this.pocForm.value.vital_history.height) {
      let bmi = (this.pocForm.value.vital_history.weight / (+hgt * +hgt)).toFixed(2);
      this.pocForm.get('vital_history.bmi').setValue(bmi)
    } else {
      this.pocForm.get('vital_history.bmi').setValue('')
      this.bmiValue = '';
    }

  }

  bmiTxtClr(bV: string, t: string) {
    if (bV != '' && bV != null && bV != undefined) {
      if (+bV <= 18.5)
        return t == 't' ? 'Underweight' : '#0000ff';
      else if (+bV <= 24.99)
        return t == 't' ? 'Normal Weight' : '#008000';
      else if (+bV <= 29.99)
        return t == 't' ? 'Overweight' : '#9acd32';
      else if (+bV <= 34.99)
        return t == 't' ? 'Obesity (Class I)' : '#ffa500';
      else if (+bV <= 39.99)
        return t == 't' ? 'Obesity (Class II)' : '#ff4500';
      else
        return t == 't' ? 'Greater Morbid Obesity' : '#8b0000';
    }
    else
      return '';
  }

    openEyeModal(){
      const modalRef = this.modalService.open(EyemdlComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = 'eye Modal'; 
    }

     //  for MMU changes on drugs

  drugtypes(){
    this.registrationService.get(`mvu/drug_types/getbyinventorytypeid/1`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.drugtypesListMMU = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  drugNames(value:any){
    this.registrationService.get(`mvu/drugs/getbydrugtypeid/${value}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.drug_listMMU = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  addRowMMU() {
    if(this.dNMMU == null || this.dNMMU == undefined || this.dNMMU.length ==0){
      this.toastr.info("Please select Drug Name")
      return

    }
     this.medName = ''; this.medType = '';
      if (this.drug_listMMU == undefined) {
        this.drug_listMMU = [];
      }
      if (this.drug_listMMU.length > 0) {
        this.medName = this.drug_listMMU.find(e => e.id == this.drugNameId).drug_name ;
        this.medType = this.drugtypesListMMU.find(e => e.id == this.drugTypeId)?.drug_type;
        this.drug_listMMU=[];

      }
    (this.pocForm.get('prescribed_medications') as FormArray).push(this.tableRowForm());


    this.dNMMU=[];
    this.dTMMU=[];

    this.medName='';
    this.medType='';
    // this.adminName='';
  }

        // Function to remove a medication from the form array
        deleteRowMMU(index: number) {
          (this.pocForm.get('prescribed_medications') as FormArray).removeAt(index);
        }

  createMedication(): FormGroup {
       
    return this.formBuilder.group({
      type: this.medType,
      id: this.drugNameId,
      name: this.medName,
      mrng: false,
      aftr: false,
      evng: false,
      night: false,
      sos: false,
      dosage: '',
      relfood: '',
      period: '',
      count: '',
      remarks: '',
      agentStock: '',
      // quantity: '',
      instruction: '',
      administration_id:'',
      administration:this.adminName
    });
  }

  dropdownMMU(){
    this.drugSettingsDrug = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
  }

  getAdministrations(){

    this.registrationService.get(`mvu/root-of-administration/getall`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.administrationList = res.data??[]
         this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

}

onSelectedAdmin(value:any){

  this.adminName = this.administrationList.find( (e: any) => e.id == value).root_of_administration;
  
  
  }
  onDrgDeSlctMMU(ctrl: string) {
    if (ctrl == 'dt')
      this.pocForm.patchValue({ drugs: { drugType: '', drugName: '' } }), this.drug_listMMU = [], this.drug_listMMU = [...this.drug_listMMU], this.dNMMU = [];
    else
      this.pocForm.patchValue({ drugs: { drugName: '' } });
  }

  onDrgSlctMMU(item: any, ctrl: string) {
    if(ctrl == 'dt'){
      this.drugTypeId=item.id
      this.drugNames(item?.id)
    }else{
      this.drugNameId=item.id
    }


}

}
