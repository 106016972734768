<div class="modal-header">
    <h4 class="modal-title">Eye Modal</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card-body" [formGroup]="examinationForm">
        <div class="container mt-4">
            <h3>Examination</h3>
              <div class="row">
                <!-- VR Column -->
                <div class="col-md-6">
                  <label for="vr">VR</label>
                  <input type="text" id="vr" class="form-control" formControlName="vr" />
          
                  <label class="mt-2">VR Glasses</label>
                 <div class="col-xl-12">
                    <div class="col-xl-12 mt-2">
                        <select class="form-control form-control-sm" formControlName="vrGlasses">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                            <option value="Not Examined">Not Examined</option>
                          </select>
                    </div>
                    <div class="col-xl-12 mt-2">
                        <select class="form-control form-control-sm" formControlName="lidVR">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                          </select>
                    </div>
                    <div class="col-xl-12 mt-2">
                        <select class="form-control form-control-sm" formControlName="conjunctivaVR">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                          </select>
                    </div>
                    <div class="col-xl-12 mt-2">
                        <select class="form-control form-control-sm" formControlName="corneaVR">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                          </select>
                    </div>
                    <div class="col-xl-12 mt-2">
                        <select class="form-control form-control-sm" formControlName="acVR">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                          </select>
                    </div>
                    <div class="col-xl-12 mt-2">
                        <select class="form-control form-control-sm" formControlName="acVR">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                          </select>
                    </div>
                    <div class="col-xl-12 mt-2">
                        <input type="text" class="form-control form-control-sm">
                    </div>
                    <div class="col-xl-12 mt-2">
                        <input type="text" class="form-control form-control-sm">
                    </div>
                    <div class="col-xl-12 mt-2">
                        <input type="text" class="form-control form-control-sm">
                    </div>
                    <div class="col-xl-12 mt-2">
                        <input type="text" class="form-control form-control-sm">
                    </div>
                 </div>

          
                 
          
                 
          
          

                </div>
          
                <!-- VL Column -->
                <div class="col-md-6">
                    <!-- Label and Input Row -->
                    <label for="vr">VL</label>
                    <input type="text" id="vr" class="form-control" formControlName="vl" />
            
                    <label class="mt-2">VL Glasses</label>
            
            
                    <!-- LID -->
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>LID</label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control form-control-sm" formControlName="lidVR">
                          <option value="">Select</option>
                          <option value="Normal">Normal</option>
                          <option value="Abnormal">Abnormal</option>
                        </select>
                      </div>
                    </div>
            
                    <!-- CONJUNCTIVA -->
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>CONJUNCTIVA</label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control form-control-sm" formControlName="conjunctivaVR">
                          <option value="">Select</option>
                          <option value="Normal">Normal</option>
                          <option value="Abnormal">Abnormal</option>
                        </select>
                      </div>
                    </div>
            
                    <!-- CORNEA -->
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>CORNEA</label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control form-control-sm" formControlName="corneaVR">
                          <option value="">Select</option>
                          <option value="Normal">Normal</option>
                          <option value="Abnormal">Abnormal</option>
                        </select>
                      </div>
                    </div>
            
                    <!-- A/C -->
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>A/C</label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control form-control-sm" formControlName="acVR">
                          <option value="">Select</option>
                          <option value="Normal">Normal</option>
                          <option value="Abnormal">Abnormal</option>
                        </select>
                      </div>
                    </div>
                    <div class="row align-items-center mt-2">
                        <div class="col-md-4 text-end">
                          <label>PUPIL</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-control form-control-sm" formControlName="vrGlasses">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                            <option value="Not Examined">Not Examined</option>
                          </select>
                        </div>
                      </div>
                      <div class="row align-items-center mt-2">
                        <div class="col-md-4 text-end">
                          <label>LENS</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-control form-control-sm" formControlName="vrGlasses">
                            <option value="">Select</option>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                            <option value="Not Examined">Not Examined</option>
                          </select>
                        </div>
                      </div>
            
                    <!-- Extra Inputs -->
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>VITREOUS</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm">
                      </div>
                    </div>
            
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>FUNDUS</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm">
                      </div>
                    </div>
            
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>IOP</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm">
                      </div>
                    </div>
            
                    <div class="row align-items-center mt-2">
                      <div class="col-md-4 text-end">
                        <label>RETINA</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm">
                      </div>
                    </div>
            
                  </div>
              </div>
           
          </div>
          

    </div>

</div>
<div class="modal-footer">
    <button type="button"  id="viewVitalButton" class="btn btn-outline-dark btn-sm" (click)="activeModal.close('Close click')">Close</button>
</div>