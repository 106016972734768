import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-eyemdl',
  templateUrl: './eyemdl.component.html',
  styleUrls: ['./eyemdl.component.css']
})
export class EyemdlComponent implements OnInit {
  examinationForm:FormGroup;
  
  constructor(public activeModal: NgbActiveModal, 
    private regService: RegistrationService, 
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.forminIt();
  }

  forminIt(){
    this.examinationForm = this.fb.group({
      vr: [''],
      vrGlasses: [''],
      lidVR: [''],
      conjunctivaVR: [''],
      corneaVR: [''],
      acVR: [''],

      vl: [''],
      vlGlasses: [''],
      lidVL: [''],
      conjunctivaVL: [''],
      corneaVL: [''],
      acVL: ['']
    });
  }
  }


